import { GroupProps } from "@react-three/fiber";
import { Text } from "@react-three/drei";
import React from "react";
import { motion } from "framer-motion-3d";
import useSound from "use-sound";
import { useStore } from "../../../store";

export const ApplySolutionButton: React.FC<
  GroupProps & { incidentId: string }
> = (props) => {
  const url = useStore((s) => s.assetUrl);
  const gameState = useStore((s) => s.gameState);
  const recordTeamChoice = useStore((s) => s.recordTeamChoice);
  const playScene = useStore((s) => s.playScene);
  const incident = gameState.incident(props.incidentId);
  const isEnabled = gameState.canTeamApplySolutionTo(incident.id);
  const [playClick] = useSound(url("/assets/sounds/_/start-hover.opus"));

  return (
    <group
      {...props}
      onClick={() => {
        if (isEnabled) {
          playClick();
          recordTeamChoice(incident.id, incident.displayedSolution);

          if (incident.id.startsWith("A"))
            playScene("first-choice-consequences");
          else playScene("second-choice-decision");
        }
      }}
    >
      <Text
        maxWidth={0.5}
        fontSize={0.03}
        font={url("/assets/fonts/Quantico-Bold.woff")}
        anchorX={"center"}
        position={[0, 0, 0.01]}
      >
        <meshToonMaterial color={isEnabled ? "black" : "#ccc"} />
        PICK THIS
      </Text>
      <motion.mesh
        castShadow
        receiveShadow
        whileHover={isEnabled ? { scale: 1.1 } : {}}
        whileTap={isEnabled ? { scale: 0.8 } : {}}
      >
        <boxGeometry args={[0.3, 0.1, 0]} />
        <meshPhongMaterial color={isEnabled ? "yellow" : "#ddd"} />
      </motion.mesh>
    </group>
  );
};
