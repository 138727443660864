import React, { useEffect } from "react";
import { TerminalCommunication } from "../../../scene-control/terminal-communication";
import { useTerminal } from "../../../scene-control/use-terminal";

export const Typewriter: React.FC<TypewriterProps> = ({
  text: Text,
  message,
  onTypingEnded,
}) => {
  const terminal = useTerminal(message, onTypingEnded);

  useEffect(() => {
    terminal.start();
  }, [message]);

  return <Text>{terminal.text}</Text>;
};

type TypewriterProps = {
  message: TerminalCommunication;
  onTypingEnded: () => void;
  text: React.FC<{ children: string }>;
};
