import getLedspClient from "../../utils/get-ledsp-client";
import { Sequence } from "../scene-control/sequence";
import { SceneInfo } from "../store/factories/create-script-store";
import { IBasicGameStore } from "../store/interfaces/basic-game-store";
import { ICommunicationsStore } from "../store/interfaces/communications-store";
import { INarrativeStore } from "../store/interfaces/narrative-store";
import { IPlayerCameraStore } from "../store/interfaces/player-camera-store";
import { IScreenPlayStore } from "../store/interfaces/screen-play-store";
import { IScriptStore } from "../store/interfaces/script-store";
import { ISequencePlayerStore } from "../store/interfaces/sequence-player-store";
import { ISoundtrackStore } from "../store/interfaces/soundtrack-store";
import { IVideoMixerStore } from "../store/interfaces/video-mixer-store";
import { shootBuilder as s } from "./utils";

export const SecondChoice: SceneInfo<
  IScreenPlayStore &
    ISoundtrackStore &
    IVideoMixerStore &
    ICommunicationsStore &
    IPlayerCameraStore &
    ISequencePlayerStore &
    INarrativeStore &
    IScriptStore &
    IBasicGameStore
> = {
  play(set, get) {
    getLedspClient().sendGameProgressEvent({
      eventType: "game-stage-entered",
      gameId: get().initProps.gameId,
      playerId: get().initProps.playerId,
      teamId: get().initProps.settings.configuration.teamId,
      step: "Incident B",
      stage: "Players decision",
    });

    set({ nextSceneIndex: "second-choice-decision" });
    get().prepareIncidentAfter("A1");

    get().setContinue(() => {
      get().displaySolution(
        get().gameState.currentIncident().id,
        get().gameState.currentIncident().displayedSolutionIndex + 1
      );
    }, "See next option");

    get().playSequence(
      Sequence.fromStages([
        (cb) => {
          get().playSoundtrack(`second-choice`);
          get().replaceShootList([
            s.addComics(["0:0"]).far().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["0:1"]).inside().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["1:0"]).make(),

            s.addComics(["1:1"]).make(),
            s.addComics(["1:2"]).far().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:3"]).inside().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:4"]).make(),
            s.addComics(["1:5"]).far().make(),
            s.addComics(["2:0"]).make(),

            s.addComics(["2:1"]).far().make(),
            s.addComics(["2:2"]).make(),
            s.addComics(["2:2"]).inside().make(),
            s.addComics(["2:2"]).make(),
            s.addComics(["2:3"]).far().make(),

            s.addComics(["3:0"]).make(),
          ]);
          get().shoot(18);
          cb();
        },
      ])
    );
  },
};
