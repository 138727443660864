import React from "react";
import ReactDom from "react-dom";
import { IRenderer } from "./renderer.interface";
import { App } from "./app";

export const render: IRenderer<{
  gamePlayInfoId: string;
  observer?: boolean;
}> = (host: HTMLElement, props, options) => {
  options = { stats: undefined, ...options };

  ReactDom.render(
    <React.StrictMode>
      <App {...props} />
    </React.StrictMode>,
    host
  );
};
