import { createAsset } from 'use-asset';
import { IAssetsLoaderStore } from '../interfaces/assets-loader-store';
import { StoreSlice } from '../utils/store-slice';
import path from 'path';
import { Howl } from 'howler';
import { TerminalCommunication } from '../../scene-control/terminal-communication';

interface IURLfn {
  (pathname: string): string;
}

export const createAssetLoaderStore: StoreSlice<IAssetsLoaderStore> = (
  set,
  get
) => ({
  assetsBaseUrl: '/',
  videoLoader: null,
  audioLoader: null,
  narrativeLoader: null,
  communicationLoader: null,

  textureUrl: (pathname: string) => join(get().assetsBaseUrl)(pathname),
  assetUrl: (pathname: string) => join(get().assetsBaseUrl)(pathname),

  setAssetUrl: (url: string) => set(init(url)),
});

export const deserializeAssetLoaderStore = (raw: any) =>
  init(raw.assetsBaseUrl);

const join = (baseUrl: string) => (pathname: string) => `${baseUrl}${pathname}`;

function init(url: string) {
  return {
    assetsBaseUrl: url,
    videoLoader: createVideoLoader(join(url)),
    audioLoader: createAudioLoader(join(url)),
    narrativeLoader: createNarrativeLoader(join(url)),
    communicationLoader: createCommunicationLoader(join(url)),
    textureUrl: join(url),
    assetUrl: join(url),
  };
}

function createVideoLoader(url: IURLfn) {
  return createAsset(async (id: string) => {
    const video = document.createElement('video');
    video.src = id;
    video.crossOrigin = 'Anonymous';
    video.loop = false;
    video.autoplay = false;
    video.volume = 0.3;

    return video;
  });
}

function createAudioLoader(url: IURLfn) {
  return createAsset(
    async (id: string) =>
      new Howl({
        src: [url(path.join('/sounds', id))],
        volume: 0.5,
      })
  );
}

function createNarrativeLoader(url: IURLfn) {
  return createAsset(async (id: string) => {
    const res = await fetch(url(`/assets/messages/narrative/${id}.txt`));

    if (!res.ok)
      return `Not Found ${url(`/assets/messages/narrative/${id}.txt`)}`;

    return await res.text();
  });
}

function createCommunicationLoader(url: IURLfn) {
  return createAsset(async (id: string) => {
    const res = await fetch(url(`/assets/messages/terminal/${id}.txt`));

    if (!res.ok) return TerminalCommunication.parse(`${res.url} not found`);

    return TerminalCommunication.parse(await res.text());
  });
}
