import { CameraShake, OrbitControls, Text } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { LayoutCamera } from 'framer-motion-3d';
import React, { useRef } from 'react';
import { useStore } from '../../store';
import { SceneController } from './scene-controller';
import { Shutter } from './shutter';

export const PlayerCamera = () => {
  const isShutterOpen = useStore(s => s.isShutterOpen);
  const closeShutter = useStore(s => s.closeShutter);
  const currentScene = useStore(s => s.currentScene);
  const isStoryStarted = useStore(s => s.isStoryStarted);
  const playScene = useStore(s => s.playScene);
  const url = useStore(s => s.assetUrl);

  const camera = useThree(s => s.camera) as THREE.PerspectiveCamera;
  const scene = useThree(s => s.scene);
  const cameraPosition = useStore(s => s.cameraPosition)(scene, camera);
  const playerCamera = useRef();

  return (
    <>
      <LayoutCamera
        ref={playerCamera}
        makeDefault
        fov={35}
        position={[0, 0, 0]}
        animate={{
          x: cameraPosition.x,
          y: cameraPosition.y,
          z: cameraPosition.z,
        }}
        transition={{ duration: 1.3 }}
      ></LayoutCamera>

      <Shutter
        isOn={isShutterOpen}
        onClick={() => {
          if (!isStoryStarted) playScene('cover');
          else if (isShutterOpen) playScene(currentScene);

          if (isShutterOpen) closeShutter();
        }}
        onClosed={() => console.log('shutter closed')}
      >
        <Text
          name="game-mask"
          color="white"
          maxWidth={100}
          fontSize={0.002}
          font={url('/assets/fonts/Quantico-Bold.woff')}
          anchorX={'center'}
          position={[0, 0, 0.01]}
          fillOpacity={(isShutterOpen && 1) || 0}
        >
          CLICK ANYWHERE TO START
        </Text>
      </Shutter>

      <CameraShake
        controls={playerCamera}
        maxPitch={0.02}
        maxYaw={0.02}
        intensity={0.8}
        maxRoll={0}
      />

      <SceneController />
      {/* <OrbitControls /> */}
    </>
  );
};
