import React from "react";
import { useTexture } from "@react-three/drei";
import * as THREE from "three";
import { FULL } from "../utils";
import { useStore } from "../../../store";

const VIDEO_SIZE_CORRECTION = new THREE.Vector3(-0.05, 0, 0);

export const VideoFrame: React.FC = () => {
  const url = useStore((s) => s.assetUrl);

  const texture = useTexture(url("/assets/textures/ui/c_video_full.svg"));

  return (
    <mesh position={[-0.002, -0.005, 0]} castShadow receiveShadow>
      <boxGeometry args={FULL.clone().add(VIDEO_SIZE_CORRECTION).toArray()} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};
