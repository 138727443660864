import { IComicPageStore } from '../interfaces/comic-page-store';
import { StoreSlice } from '../utils/store-slice';

export const createComicPageStore: StoreSlice<IComicPageStore> = (
  set,
  get
) => ({
  comicsLayout: [
    ['cover', 'video-1'],
    [
      'large:terminal-1',
      'large:narrative-1',
      'small:terminal-2',
      'video-2',
      'large:narrative-2',
      'small:narrative-3',
    ],
    ['question-0', 'large_solo:terminal-3', 'video-3', 'small:narrative-3'],
    ['question-1', 'video-4', 'small:terminal-4'],
    ['video-5', 'large:narrative-4'],
  ],
});
