import React from "react"

export const Table: React.FC<TableProps> = props => (
  <table style={{ width: '100%' }}>
    <thead>
      <tr>
        {
          props.cols.map((col, j) => (
            <th style={{ textAlign: 'left' }} key={j}>{col}</th>
          ))
        }
      </tr>
    </thead>
    <tbody>
      {
        props.rows.map((row, i) => (
          <tr key={i}>{
            props.cols.map((col, j) => (
              <td key={j}>{row[col]}</td>
            ))
          }</tr>
        ))
      }
    </tbody>
  </table>
)


export type TableProps = {
  readonly cols: Array<string>
  readonly rows: Array<object>
}
