import React, { useMemo } from 'react';
import { useStore } from '../../store';
import { styles } from './styles';
import { UI } from './ui';

export const BoardFrame: React.FC<BoardFrameProps> = props => {
  const debug = useStore(s => s.areVisualGuidesVisible);
  const s = useMemo(() => styles({ debug }), [debug]);

  return (
    <div style={s.main}>
      <UI />
      <div style={s.canvas}>{props.children}</div>
    </div>
  );
};

type BoardFrameProps = any;
