import Immutable from 'immutable';
import { StoreSlice } from '../utils/store-slice';
import { ITimerStore } from '../interfaces/timer-store';
import { IBasicGameStore } from '../interfaces/basic-game-store';
import { ISoundStore } from '../interfaces/sound-store';

export const createTimerStore: StoreSlice<
  ITimerStore,
  IBasicGameStore & ISoundStore
> = (set, get) => ({
  expiryTimestamp: Date.now(),
  laps: Immutable.List<number>(),
  timerDurationInSeconds: 0,
  isTimerStarted: false,
  isTimerVisible: false,
  isTimerExpired: false,
  isTimerStopped: false,

  isTimerGoingToExpire: (remainingSeconds: number) =>
    remainingSeconds <= 0.1 * get().timerDurationInSeconds,

  initTimer: durationInSeconds => {
    set({
      isTimerStarted: false,
      isTimerVisible: true,
      timerDurationInSeconds: durationInSeconds,
    });
  },
  startTimer: startTimestamp => {
    set(s => ({
      isTimerStarted: true,
      expiryTimestamp: startTimestamp + s.timerDurationInSeconds * 1000,
    }));
  },

  stopTimer: () => {
    get().stopAudio('timer-last-seconds');
    set(() => ({
      isTimerStopped: true,
    }));
  },

  timerExpired: () => {
    if (!get().isTimerExpired) {
      set({ isTimerExpired: true });
      get().stopAudio('timer-last-seconds');
      get().gameOver();
    }
  },

  recordLap(timestamp: number) {
    set(s => ({
      laps: s.laps.push(timestamp),
    }));
  },

  computeProgress: (now: number) => {
    if (!get().isTimerStarted) return 0;

    const timeLeft = get().expiryTimestamp - now;
    const timeLeftInSeconds = timeLeft / 1000;
    return Math.min(
      ((get().timerDurationInSeconds - timeLeftInSeconds) /
        get().timerDurationInSeconds) *
        100,
      100
    );
  },

  firstStopProgress: (progress: number) =>
    get().laps.has(0) ? get().computeProgress(get().laps.get(0)) : progress,

  isFirstStopAboutToExpire: (progress: number) =>
    !get().laps.has(0) && progress >= PHASE_1_EXPIRATION_ALERT_THRESHOLD,

  isSecondStopAboutToExpire: (progress: number) =>
    !get().laps.has(1) && progress >= PHASE_2_EXPIRATION_ALERT_THRESHOLD,

  isFirstStopExpired: (progress: number) =>
    progress > PHASE_1_EXPIRATION_THRESHOLD,

  isSecondStopExpired: (progress: number) => progress >= 100,

  isFirstStopEnded: (progress: number) => get().laps.has(0),

  isSecondStopEnded: (progress: number) => progress >= 100,

  shouldAlarmPlay: (progress: number, alarmCount: number) => {
    if (!get().isTimerStarted) return false;
    if (get().isTimerExpired) return false;
    if (get().isTimerStopped) return false;

    if (
      get().isFirstStopAboutToExpire(progress) &&
      !get().isFirstStopEnded(progress) &&
      alarmCount < 1
    )
      return true;

    if (get().isSecondStopAboutToExpire(progress) && progress < 90) return true;

    return false;
  },

  shouldHearthBeatPlay: (progress: number) => {
    if (!get().isTimerStarted) return false;
    if (get().isTimerExpired) return false;
    if (get().isTimerStopped) return false;

    return progress >= 90;
  },
});

const PHASE_1_EXPIRATION_ALERT_THRESHOLD: number = 40;
const PHASE_1_EXPIRATION_THRESHOLD: number = 50;
const PHASE_2_EXPIRATION_ALERT_THRESHOLD: number = 75;

export const deserializeTimerStore = (raw: any) => ({
  expiryTimestamp: raw.expiryTimestamp,
  laps: Immutable.List(raw.laps),
  timerDurationInSeconds: raw.timerDurationInSeconds,
  isTimerStarted: raw.isTimerStarted,
  isTimerVisible: raw.isTimerVisible,
});
