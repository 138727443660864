import React from 'react';
import { useStore } from '../../store';
import BoardFramePlayersList from '../board-frame-players-list';

export const PlayersListController = () => {
  const gameState = useStore(s => s.gameState);
  const isSolutionSelectorVisible = useStore(s => s.isSolutionSelectorVisible);
  const recordPlayerChoice = useStore(s => s.recordPlayerChoice);

  return (
    <BoardFramePlayersList
      isVisible={isSolutionSelectorVisible}
      displayedSolution={gameState.currentIncident().displayedSolution}
      applicableSolutions={gameState.currentIncident().applicableSolutions}
      handlePlayerClick={(playerId: string) =>
        recordPlayerChoice(
          playerId,
          gameState.currentIncident().id,
          gameState.currentIncident().displayedSolution
        )
      }
      players={gameState.playersIncidentReport(gameState.currentIncident().id)}
    />
  );
};
