import { useState } from 'react';

export default function useScaleFactor() {
  const [zoomData, setZoomData] = useState({
    zoom: 1,
    devicePxPerCssPx: 1,
  });

  typeof window !== 'undefined' &&
    window.addEventListener('resize', function () {
      const zoom =
        Math.round((window.outerWidth / window.innerWidth) * 100) / 100;
      setZoomData({
        zoom: zoom,
        devicePxPerCssPx: zoom * window.devicePixelRatio,
      });
    });

  return 1 / (zoomData.devicePxPerCssPx / zoomData.zoom);
}
