import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store';
import { BoardFrameTimer } from '../board-frame-timer';

export const Timer = () => {
  const isTimerStarted = useStore(s => s.isTimerStarted);
  const isTimerStopped = useStore(s => s.isTimerStopped);
  const isTimerVisible = useStore(s => s.isTimerVisible);

  if (!isTimerStarted || isTimerStopped || !isTimerVisible) return null;
  return <TimerController />;
};

const TimerController = () => {
  const playSound = useStore(s => s.playSound);

  const isTimerStarted = useStore(s => s.isTimerStarted);
  const timerExpired = useStore(s => s.timerExpired);
  const computeProgress = useStore(s => s.computeProgress);
  const firstStopProgress = useStore(s => s.firstStopProgress);
  const isFirstStopAboutToExpire = useStore(s => s.isFirstStopAboutToExpire);
  const isSecondStopAboutToExpire = useStore(s => s.isSecondStopAboutToExpire);
  const isFirstStopExpired = useStore(s => s.isFirstStopExpired);
  const isSecondStopExpired = useStore(s => s.isSecondStopExpired);
  const isFirstStopEnded = useStore(s => s.isFirstStopEnded);
  const isSecondStopEnded = useStore(s => s.isSecondStopEnded);
  const shouldAlarmPlay = useStore(s => s.shouldAlarmPlay);
  const shouldHearthBeatPlay = useStore(s => s.shouldHearthBeatPlay);

  const [progress, setProgress] = useState<number>(0);
  const [alarmCount, setAlarmCount] = useState<number>(0);
  const [heartBeatCount, setHeartBeatCount] = useState<number>(0);

  useInterval(() => {
    if (shouldAlarmPlay(progress, alarmCount)) {
      playSound('timer-expiring');
      setAlarmCount(alarmCount + 1);
    }

    if (shouldHearthBeatPlay(progress) && !heartBeatCount) {
      playSound('timer-last-seconds');
      setHeartBeatCount(1);
    }

    if (progress >= 99.9 && isTimerStarted && isFirstStopEnded(progress))
      timerExpired();
    else setProgress(computeProgress(Date.now()));
  }, 2000);

  return (
    <BoardFrameTimer
      stops={[
        {
          progress: Math.min(firstStopProgress(progress), progress),
          isAboutToExpire: isFirstStopAboutToExpire(progress),
          isExpired: isFirstStopExpired(progress),
          isEnded: isFirstStopEnded(progress),
        },
        {
          progress: Math.min(progress, 100),
          isAboutToExpire: isSecondStopAboutToExpire(progress),
          isExpired: isSecondStopExpired(progress),
          isEnded: isSecondStopEnded(progress),
        },
      ]}
    />
  );
};

export default function useInterval(
  callback: () => void,
  delay: number | null
) {
  const savedCallback = useRef(callback);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) return;

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}
