/* eslint-disable indent */
/* eslint-disable default-case */
import Typewriter from 'typewriter-effect/dist/core';
import Immutable from 'immutable';
import { Parser } from 'simple-text-parser';

export class TerminalCommunication {
  private static parser = new Parser().addRule(
    /\{[0-9]+\.*[0-9]*\}/gi,
    token => ({ type: 'wait', text: token.slice(1, -1) })
  );

  static parse(str: string) {
    return new TerminalCommunication(
      TerminalCommunication.parser.toTree(str).map(node => {
        switch (node.type) {
          case 'text':
            return `write::${node.text}`;
          case 'wait':
            return `wait::${node.text}`;
        }
      })
    );
  }

  private readonly commands: Immutable.List<string>;

  constructor(commands: Iterable<string>) {
    this.commands = Immutable.List(commands);
  }

  toString() {
    return this.commands
      .map(this.parseCommand)
      .filter(isWriteCommand)
      .map(c => c.text)
      .join('');
  }

  toTypeWriter(opts: {
    onAddCharacter: (character: string) => void;
    onRemoveCharacter: () => void;
    onCommunicationEnded?: () => void;
  }): ITypewriter {
    const typewriter: ITypewriter = new Typewriter(null, {
      delay: 20,
      onCreateTextNode: opts.onAddCharacter,
      onRemoveNode: opts.onRemoveCharacter,
    });

    for (const command of this.commands)
      this.programTypewriter(typewriter, this.parseCommand(command));

    opts.onCommunicationEnded &&
      typewriter.callFunction(opts.onCommunicationEnded);

    return typewriter;
  }

  private parseCommand(command: string): TerminalCommands {
    const [type, arg] = command.split('::');

    switch (type) {
      case 'write':
        return {
          type: TerminalCommandKind.Write,
          text: arg.toUpperCase(),
        };
      case 'wait':
        return {
          type: TerminalCommandKind.Wait,
          seconds: Number.parseFloat(arg),
        };
    }
  }

  private programTypewriter(
    typewriter: ITypewriter,
    command: TerminalCommands
  ) {
    switch (command.type) {
      case TerminalCommandKind.Wait:
        typewriter.pauseFor(command.seconds * 1000);
        break;

      case TerminalCommandKind.Write:
        typewriter.typeString(command.text);
        break;
    }
  }
}

function isWriteCommand(
  command: TerminalCommands
): command is IWriteTerminalCommand {
  return command.type === TerminalCommandKind.Write;
}

interface ITypewriter {
  typeString(str: string): ITypewriter;
  pauseFor(milliseconds: number): ITypewriter;
  callFunction(cb: () => void): ITypewriter;
  start(): ITypewriter;
  stop(): ITypewriter;
}

enum TerminalCommandKind {
  Write,
  Wait,
}
interface ITerminalCommand {
  readonly type: TerminalCommandKind;
}

interface IWriteTerminalCommand extends ITerminalCommand {
  readonly type: TerminalCommandKind.Write;
  readonly text: string;
}
interface IWaitTerminalCommand extends ITerminalCommand {
  readonly type: TerminalCommandKind.Wait;
  readonly seconds: number;
}

type TerminalCommands = IWriteTerminalCommand | IWaitTerminalCommand;
