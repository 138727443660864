import { CSSProperties } from 'react';

export const styles = (opts: { debug: boolean }): IStyleMap => ({
  main: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

interface IStyleMap {
  [id: string]: CSSProperties;
}
