import { __assign } from 'tslib';
import { useMemo, createElement } from 'react';
import { isMotionValue, resolveMotionValue, filterProps } from 'framer-motion';
import { useHover } from './gestures/use-hover.mjs';
import { useTap } from './gestures/use-tap.mjs';

var useRender = function (Component, props, _projectionId, ref, _state, isStatic, visualElement) {
    var visualProps = useVisualProps(props);
    /**
     * If isStatic, render motion values as props
     * If !isStatic, render motion values as props on initial render
     */
    return createElement(Component, __assign(__assign(__assign(__assign(__assign({ ref: ref }, filterProps(props, false, false)), visualProps), { onUpdate: props.onInstanceUpdate }), useHover(isStatic, props, visualElement)), useTap(isStatic, props, visualElement)));
};
function useVisualProps(props) {
    return useMemo(function () {
        var visualProps = {};
        for (var key in props) {
            var prop = props[key];
            if (isMotionValue(prop)) {
                visualProps[key] = prop.get();
            }
            else if (Array.isArray(prop) && prop.includes(isMotionValue)) {
                visualProps[key] = prop.map(resolveMotionValue);
            }
        }
        return visualProps;
    }, []);
}

export { useRender };
