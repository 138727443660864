import Immutable from 'immutable';
import { SceneInfo } from '../store/factories/create-script-store';
import { AThreatToM1 } from './02.a-threat-to-m1';
import { Cover } from './01.cover';
import { TheMission } from './03.the-mission';
import { FirstChoice } from './04.first-choice';
import { FirstChoiceDecision } from './05.first-choice-decision';
import { FirstChoiceConsequences } from './06.first-choice-consequences';
import { SecondChoice } from './07.second-choice';
import { SecondChoiceDecision } from './08.second-choice-decision';
import { SecondChoiceConsequences } from './09.second-choice-consequences';
import { SceneNames } from '../store/interfaces/script-store';
import { GameOver } from './10.game-over';
import { TeamScene } from './04-0.team';

export const scenes = Immutable.Map<SceneNames, SceneInfo>({
  cover: Cover,
  'a-threat-to-m1': AThreatToM1,
  'the-mission': TheMission,
  team: TeamScene,
  'first-choice': FirstChoice,
  'first-choice-decision': FirstChoiceDecision,
  'first-choice-consequences': FirstChoiceConsequences,
  'second-choice': SecondChoice,
  'second-choice-decision': SecondChoiceDecision,
  'second-choice-consequences': SecondChoiceConsequences,
  'game-over': GameOver,
});
