import React from 'react';
import { motion } from 'framer-motion';

interface IProps {
  progress: number;
  phase1Progress: number;
}

const Phase2Bar = ({ progress, phase1Progress }: IProps) => (
  <motion.span
    id="stop2bar"
    style={{
      height: '100%',
      display: 'inline-block',
      backgroundColor: '#8BC63E',
    }}
    animate={{
      width: `${progress - phase1Progress}%`,
    }}
  />
);

export default Phase2Bar;
