import React, { useMemo } from 'react';
import { useStore } from '../../store';
import { styles } from './styles';
import { useTimer } from 'react-timer-hook';
import Ticks from './ticks';
import { motion } from 'framer-motion';
import FinishCircle from './finish-circle';
import MiddleTick from './middle-tick';
import BarsContainer from './bars-container';
import Phase1Bar from './phase-1-bar';
import Phase1ExpiredBar from './phase-1-expired-bar';
import Phase2Bar from './phase-2-bar';
import Handle from './handle';
import TimerContainer from './timer-container';

export const BoardFrameTimer: React.FC<BoardFrameTimerProps> = ({ stops }) => {
  return (
    <TimerContainer>
      <Ticks
        stop1IsAboutToExpire={stops[0].isAboutToExpire}
        stop2IsAboutToExpire={stops[1].isAboutToExpire}
      />

      <FinishCircle />

      <MiddleTick />

      <BarsContainer>
        <Phase1Bar progress={stops[0].progress} />
        <Phase1ExpiredBar
          progress={stops[0].progress}
          isPhaseEnded={!!stops[0].isEnded}
        />

        <Phase2Bar
          progress={stops[1].progress}
          phase1Progress={stops[0].progress}
        />

        <Handle
          phase2Progress={stops[1].progress}
          isFlashing={stops[0].isAboutToExpire || stops[1].isAboutToExpire}
        />
      </BarsContainer>
    </TimerContainer>
  );
};

type BoardFrameTimerProps = {
  stops: [Stop, Stop]; // TODO
};
type Stop = {
  progress: number;
  isAboutToExpire: boolean;
  isExpired: boolean;
  isEnded: boolean;
};
