import Immutable from 'immutable';

import transit from 'transit-immutable-js';
import {
  IVideoChannelStatus,
  VideoSource,
} from '../../store/interfaces/video-mixer-store';

export class VideoChannelStatus
  extends Immutable.Record(
    {
      videoPath: '',
      openingThumbPath: '',
      closingThumbPath: '',
      currentThumbPath: '',
      isPlaying: false,
    },
    'VideoChannelStatus'
  )
  implements IVideoChannelStatus
{
  static decode(raw: any) {
    return transit.withRecords([VideoChannelStatus]).fromJSON(raw);
  }

  static encode(target: VideoChannelStatus) {
    return transit.withRecords([VideoChannelStatus]).toJSON(target);
  }

  static fromVideoSource(source: VideoSource) {
    return new this({ ...source, currentThumbPath: source.openingThumbPath });
  }

  playStarted() {
    return this.set('isPlaying', true);
  }

  playEnded() {
    return this.set('isPlaying', false).set(
      'currentThumbPath',
      this.closingThumbPath
    );
  }
}
