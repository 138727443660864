import React from 'react';
import { Box, Flex } from '@react-three/flex';

export const ComicStrip: React.FC<{
  children: React.ReactElement[];
}> = props => (
  <Flex
    flexDirection={'column'}
    alignItems={'flex-start'}
    align={'flex-start'}
    alignContent={'flex-start'}
  >
    {props.children.map((child, i) => (
      <Box key={i} margin={0.1}>
        {child}
      </Box>
    ))}
  </Flex>
);
