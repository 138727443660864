"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const http_client_1 = require("./http-client");
const ledsp_emulator_1 = require("./ledsp-emulator");
const game_progress_1 = require("./game-progress");
const env_1 = require("./env");
const game_flow_debugger_1 = require("./game-flow-debugger");
class LedspClient {
    constructor(config) {
        this.config = config;
        if (!config.gamePlayInfoId)
            throw new Error("Missing gamePlayInfoId");
        this.gamePlayInfoId = config.gamePlayInfoId;
        if (!env_1.LEDSP_API_ENDPOINT[config.environment])
            throw new Error(`Unknown environment for ledsp-sdk: ${config.environment}.`);
        if (config.emulate && !config.gameConceptToEmulate)
            throw new Error("You must provide a game concept to emulate.");
        this.ledspHttpClient = new http_client_1.default(env_1.LEDSP_API_ENDPOINT[config.environment].concat("/", env_1.LEDSP_API_BASEPATH));
        this.ledspEmulator = new ledsp_emulator_1.LedspEmulator(config.gamePlayInfoId, config.gameConceptToEmulate);
        if (config.emulate && config.gameEventsMountPointId)
            (0, game_flow_debugger_1.GameFlowDebugger)(config.gameEventsMountPointId, this);
    }
    gamePlayInfo(opts = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.config.emulate)
                return this.ledspEmulator.gamePlayInfo(opts);
            return yield this.ledspHttpClient.get(`game-launcher/game-play-info/${this.gamePlayInfoId}`);
        });
    }
    sendGameProgressEvent(event) {
        return __awaiter(this, void 0, void 0, function* () {
            const eventToSend = Object.assign(Object.assign({}, event), { id: `${event.gameId}.${typeof window !== "undefined" &&
                    typeof window.crypto === "object" &&
                    typeof window.crypto.randomUUID === "function"
                    ? window.crypto.randomUUID()
                    : Math.ceil(Math.random() * Date.now()).toString(36)}`, timestamp: Date.now() });
            if (this.config.emulate)
                return this.ledspEmulator.sendGameProgressEvent(eventToSend);
            if (!game_progress_1.GAME_PROGRESS_EVENT_TYPES.includes(event.eventType))
                throw new Error(`Game Progress Event: unknown event type: ${event.eventType}`);
            return yield this.ledspHttpClient.post("games-progresses", eventToSend);
        });
    }
    get gameProgressEvents() {
        return this.ledspEmulator.events;
    }
    saveResults(results) {
        return __awaiter(this, void 0, void 0, function* () { });
    }
    debriefingInfo(debriefingId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.config.gameConceptToEmulate)
                return;
            return this.ledspHttpClient.get(`game-results-storages/payloads?gamingSessionIds[]=${debriefingId}`);
        });
    }
    static getInstance(config) {
        if (!LedspClient.instance)
            LedspClient.instance = new LedspClient(config);
        return LedspClient.instance;
    }
}
exports.default = LedspClient;
