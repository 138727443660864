import { useStore } from "../../../store";
import React, { Suspense, useEffect, useState } from "react";
import { ClaimTerminalText } from "./claim-terminal-text";
import { FullTerminalFrame } from "./full-terminal-frame";
import { FullMessageTerminalText } from "./message-terminal-text";
import { TerminalTextLoader } from "./terminal-text-loader";
import { Typewriter } from "./typewriter";
import { CommunicationStatus } from "../../../model/communication-status";

export const FullTerminal: React.FC<{ name: string }> = (props) => {
  const status = useStore((s) => s.terminals).get(
    props.name,
    CommunicationStatus.Empty()
  );

  const endCommunication = useStore((s) => s.endCommunication);

  const [messageIsEnded, setMessageIsEnded] = useState(status.isEnded);

  useEffect(() => {
    setMessageIsEnded(status.isEnded);
  }, [status.isEnded]);

  return (
    <>
      <Suspense fallback={null}>
        <TerminalTextLoader
          message={status.message}
          isStarted={status.isStarted}
          isEnded={messageIsEnded}
          typewriter={(props) => (
            <Typewriter
              {...props}
              onTypingEnded={() => setMessageIsEnded(true)}
            />
          )}
          text={FullMessageTerminalText}
        />
      </Suspense>

      <Suspense fallback={null}>
        <TerminalTextLoader
          message={status.claim}
          isStarted={messageIsEnded}
          isEnded={status.isEnded}
          typewriter={(props) => (
            <Typewriter
              {...props}
              onTypingEnded={() => endCommunication(status.terminalId)}
            />
          )}
          text={ClaimTerminalText}
        />
      </Suspense>

      <FullTerminalFrame />
    </>
  );
};
