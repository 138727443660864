import { useStore } from "../../../store";
import React, { Suspense } from "react";
import { FullTerminalFrame } from "./full-terminal-frame";
import { FullSoloMessageTerminalText } from "./message-terminal-text";
import { TerminalTextLoader } from "./terminal-text-loader";
import { Typewriter } from "./typewriter";
import { CommunicationStatus } from "../../../model/communication-status";

export const FullTerminalSolo: React.FC<{ name: string }> = (props) => {
  const status = useStore((s) => s.terminals).get(
    props.name,
    CommunicationStatus.Empty()
  );

  const endCommunication = useStore((s) => s.endCommunication);

  return (
    <>
      <Suspense fallback={null}>
        <TerminalTextLoader
          message={status.message}
          isStarted={status.isStarted}
          isEnded={status.isEnded}
          typewriter={(props) => (
            <Typewriter
              {...props}
              onTypingEnded={() => endCommunication(status.terminalId)}
            />
          )}
          text={FullSoloMessageTerminalText}
        />
      </Suspense>

      <FullTerminalFrame />
    </>
  );
};
