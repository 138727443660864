/* eslint-disable indent */
import React from 'react';

interface IProps {
  stop1IsAboutToExpire?: boolean;
  stop2IsAboutToExpire?: boolean;
}

const Ticks = ({ stop1IsAboutToExpire, stop2IsAboutToExpire }: IProps) => (
  <span
    style={{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
      padding: 'auto 4px',
    }}
  >
    {Array.from({ length: 40 }, (_: unknown, tickIndex: number) => (
      <span
        key={tickIndex}
        style={{
          borderRadius: '50%',
          width: '4px',
          height: '4px',
          backgroundColor:
            tickIndex <= 40 / 2 - 1
              ? stop1IsAboutToExpire
                ? 'FireBrick'
                : '#FFDD17'
              : stop2IsAboutToExpire
              ? 'FireBrick'
              : '#8BC63E',
        }}
      />
    ))}
  </span>
);

export default Ticks;
