import { useStore } from "../../../store";
import React from "react";
import { TerminalCommunication } from "../../../scene-control/terminal-communication";

export const TerminalTextLoader: React.FC<TerminalTextProps> = (props) => {
  const loader = useStore((s) => s.communicationLoader);
  const message = loader.read(props.message);

  if (!props.isStarted) return null;
  if (props.isEnded) return <props.text>{message.toString()}</props.text>;
  return <props.typewriter message={message} text={props.text} />;
};

type TerminalTextProps = {
  isStarted: boolean;
  isEnded: boolean;
  message: string;
  typewriter: React.FC<{
    message: TerminalCommunication;
    text: React.FC<{ children: string }>;
  }>;
  text: React.FC<{ children: string }>;
};
