"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameFlowDebugger = void 0;
const game_progress_1 = require("./game-progress");
const GameFlowDebugger = (mountPoint, client) => {
    setInterval(() => {
        const element = document.getElementById(mountPoint);
        if (!element) {
            console.error(`GameFlowDebugger: mount point ${mountPoint} not found`);
            return;
        }
        element.innerHTML = `
        <div
          style="
            position: fixed;
            right: 0;
            top: 50%;
            height: 400px;
            overflow-y: scroll;
            background-color: white;
            padding: 10px;
            color: black;
            width: 300px;
            opacity: 0.7;
            z-index: 1000;
          "
        >
          Game progress events:
          <ul>
            ${client.gameProgressEvents
            .map((e) => `
                <li>
                  Type: ${e.eventType}
                  ${e.eventType === game_progress_1.GAME_STAGE_ENTERED
            ? `
                        <br />
                        (${e.step} -> ${e.stage})
                      `
            : ""}
                </li>
              `)
            .join("\n")}
          </ul>
        </div>
      `;
    }, 1000);
};
exports.GameFlowDebugger = GameFlowDebugger;
