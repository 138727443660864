import { motion } from 'framer-motion';
import React from 'react';
import { useStore } from '../../store';
import { useSound } from 'use-sound';

interface IProps {
  isVisible: boolean;
  canGoBack: boolean;
  canGoForward: boolean;
  goBack: () => void;
  goForward: () => void;
}

export const NavigationButtons = ({
  isVisible,
  canGoBack,
  canGoForward,
  goBack,
  goForward,
}: IProps) => {
  const url: (path: string) => string = useStore(s => s.assetUrl);

  const [playbackRate, setPlaybackRate] = React.useState(0.75);

  const [clickSound] = useSound(url('/assets/sounds/mechanical/click00.wav'), {
    playbackRate,
    interrupt: true,
  });

  const playSoundOnClick = () => {
    setPlaybackRate(playbackRate + Math.random() * 0.1 - 0.05);
    clickSound();
  };

  return (
    <motion.div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        userSelect: 'none',
        MozWindowDragging: 'no-drag',
        padding: '0 24px',
        pointerEvents: 'auto',
      }}
      animate={
        isVisible
          ? { opacity: 1, pointerEvents: 'auto' }
          : { opacity: 0, pointerEvents: 'none' }
      }
    >
      <motion.img
        whileHover={{ scale: 1.05, transition: { duration: 0.1 } }}
        whileTap={{
          scale: 0.8,
          filter: 'saturate(0) brightness(0.5)',
          transition: { duration: 0.05 },
        }}
        src={url(
          `/assets/textures/ui/ui_nav_arrow_back${
            (!canGoBack && '_disabled') || ''
          }.svg`
        )}
        onClick={() => {
          if (!canGoBack) return;
          playSoundOnClick();
          goBack();
        }}
        style={{
          cursor: canGoBack ? 'pointer' : 'not-allowed',
          height: '54px',
          width: '54px',
          pointerEvents: 'inherit',
          filter: 'saturate(1) brightness(1)',
        }}
      />
      <motion.img
        whileHover={{ scale: 1.05, transition: { duration: 0.1 } }}
        whileTap={{
          scale: 0.8,
          filter: 'saturate(0) brightness(0.5)',
          transition: { duration: 0.05 },
        }}
        src={url(
          `/assets/textures/ui/ui_nav_arrow_next${
            (!canGoForward && '_disabled') || ''
          }.svg`
        )}
        onClick={() => {
          if (!canGoForward) return;
          playSoundOnClick();
          goForward();
        }}
        style={{
          cursor: canGoForward ? 'pointer' : 'not-allowed',
          height: '54px',
          width: '54px',
          pointerEvents: 'inherit',
          filter: 'saturate(1) brightness(1)',
        }}
      />
    </motion.div>
  );
};

const NavigationArrows = () => {
  return <></>;
};
