import { useStore } from '../../../store';
import { Text } from '@react-three/drei';
import React from 'react';
import { FULL, SMALL } from '../utils';

export const FullMessageTerminalText: React.FC<{
  children: string;
}> = props => {
  const url = useStore(s => s.assetUrl);

  return (
    <Text
      color="black"
      maxWidth={FULL.x * 0.5}
      fontSize={0.03}
      font={url('/assets/fonts/Quantico-Italic.woff')}
      anchorX={'left'}
      anchorY={'top'}
      position={[-0.8, 0.32, 0.01]}
    >
      {props.children}
    </Text>
  );
};

export const SmallMessageTerminalText: React.FC<{
  children: string;
}> = props => {
  const url = useStore(s => s.assetUrl);

  return (
    <Text
      color="black"
      maxWidth={SMALL.x * 0.8}
      fontSize={0.03}
      font={url('/assets/fonts/Quantico-Italic.woff')}
      anchorX={'left'}
      anchorY={'top'}
      position={[-0.3, 0.32, 0.01]}
    >
      {props.children}
    </Text>
  );
};

export const FullSoloMessageTerminalText: React.FC<{
  children: string;
}> = props => {
  const url = useStore(s => s.assetUrl);

  return (
    <Text
      color="black"
      maxWidth={FULL.x * 0.9}
      fontSize={0.03}
      font={url('/assets/fonts/Quantico-Italic.woff')}
      anchorX={'left'}
      anchorY={'top'}
      position={[-0.8, 0.32, 0.01]}
    >
      {props.children}
    </Text>
  );
};
