import path from "path";
import React from "react";
import { useStore } from "../../store";
import { Table } from "./table";

export const VideoChannels = () => {

  const videoChannels = useStore(s => s.videoChannels);

  const cols = [
    'name',
    'icon',
    'videoPath',
    'thumb',
  ];
  const rows = videoChannels.map((channel, i) => ({
    name: i,
    icon: channel.isPlaying && '▶ ' || '❙❙',
    videoPath: path.basename(channel.videoPath),
    thumb: path.basename(channel.currentThumbPath),
  }))
    .toList()
    .toArray()

  return (
    <Table cols={cols} rows={rows} />
  )

}
