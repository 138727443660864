import { IDevSettingsStore } from '../interfaces/dev-settings-store';
import { StoreSlice } from '../utils/store-slice';

export const createDevSettingsStore: StoreSlice<IDevSettingsStore> = (
  set,
  get
) => ({
  areDevToolsVisible: false,
  areVisualGuidesVisible: false,

  toggleDevTools: () =>
    set(s => ({ areDevToolsVisible: !s.areDevToolsVisible })),

  toggleVisualGuides: () =>
    set(s => ({ areVisualGuidesVisible: !s.areVisualGuidesVisible })),
});
