import React from 'react';
import { motion } from 'framer-motion';

interface IProps {
  progress: number;
}

const Phase1Bar = ({ progress }: IProps) => (
  <motion.span
    id="stop-1-bar"
    style={{
      height: '100%',
      display: 'inline-block',
      backgroundColor: '#FFDD17',
    }}
    animate={{
      width: `${Math.min(progress, 50)}%`,
    }}
  />
);

export default Phase1Bar;
