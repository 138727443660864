/* eslint-disable indent */
import create, { GetState, SetState, UseBoundStore } from 'zustand';
import { persist, StoreApiWithPersist } from 'zustand/middleware';
import {
  createAssetLoaderStore,
  deserializeAssetLoaderStore,
} from './factories/create-asset-loader-store';
import {
  createBaseGameStore,
  deserializeBaseGameStore,
  serializeBaseGameStore,
} from './factories/create-base-game-store';
import { createComicPageStore } from './factories/create-comic-page-store';
import {
  createCommunicationsStore,
  deserializeCommunicationsStore,
  serializeCommunicationsStore,
} from './factories/create-communications-store';
import { createDevSettingsStore } from './factories/create-dev-settings-store';
import { createLightDimmerStore } from './factories/create-light-dimmer-store';
import {
  createNarrativeStore,
  deserializeNarrativeStore,
  serializeNarrativeStore,
} from './factories/create-narrative-store';
import {
  createPlayerCameraStore,
  deserializePlayerCameraStore,
  serializePlayerCameraStore,
} from './factories/create-player-camera-store';
import {
  createScreenPlayStore,
  deserializeScreenPlayStore,
  serializeScreenPlayStore,
} from './factories/create-screen-play-store';
import { createScriptStore } from './factories/create-script-store';
import {
  createSequencePlayerStore,
  deserializeSequencePlayerStore,
  serializeSequencePlayerStore,
} from './factories/create-sequence-player-store';
import {
  createSoundStore,
  deserializeSoundStore,
} from './factories/create-sound-store';
import { createSoundtrackStore } from './factories/create-soundtrack-store';
import {
  createTimerStore,
  deserializeTimerStore,
} from './factories/create-timer-store';
import {
  createVideoMixerStore,
  deserializeVideoMixerStore,
  serializeVideoMixerStore,
} from './factories/create-video-mixer-store';

const createRootSlice = (set: SetState<any>, get: GetState<any>) => ({
  ...createLightDimmerStore(set, get),
  ...createAssetLoaderStore(set, get),
  ...createBaseGameStore(set, get),
  ...createTimerStore(set, get),
  ...createDevSettingsStore(set, get),
  ...createComicPageStore(set, get),
  ...createPlayerCameraStore(set, get),
  ...createScreenPlayStore(set, get),
  ...createVideoMixerStore(set, get),
  ...createScriptStore(set, get),
  ...createSoundStore(set, get),
  ...createSoundtrackStore(set, get),
  ...createCommunicationsStore(set, get),
  ...createSequencePlayerStore(set, get),
  ...createNarrativeStore(set, get),
});

type StoreType = ReturnType<typeof createRootSlice>;

export const useStore: UseBoundStore<StoreType> &
  StoreApiWithPersist<StoreType> = create(
  persist(createRootSlice, {
    name: 'fireballs',

    onRehydrateStorage: state => {
      console.log('»» store hydration started');
      return (state, error) => {
        if (error) console.error('an error happened during hydration', error);
        else {
          state.setSuperFocus(state.isSuperFocusOn);
          state.openShutter();
          state.reloadAllVideoChannels();

          console.log('»» store hydration finished');
        }
      };
    },

    serialize: storage =>
      JSON.stringify({
        state: {
          ...storage.state,
          ...serializeBaseGameStore(storage.state),
          ...serializeScreenPlayStore(storage.state),
          ...serializeVideoMixerStore(storage.state),
          ...serializePlayerCameraStore(storage.state),
          ...serializeCommunicationsStore(storage.state),
          ...serializeSequencePlayerStore(storage.state),
          ...serializeNarrativeStore(storage.state),
        },
        version: storage.version,
      }),
    deserialize: str => {
      const obj = JSON.parse(str);
      const store = {
        state: {
          ...obj.state,
          ...deserializeTimerStore(obj.state),
          ...deserializeAssetLoaderStore(obj.state),
          ...deserializeBaseGameStore(obj.state),
          ...deserializeScreenPlayStore(obj.state),
          ...deserializeVideoMixerStore(obj.state),
          ...deserializePlayerCameraStore(obj.state),
          ...deserializeCommunicationsStore(obj.state),
          ...deserializeSequencePlayerStore(obj.state),
          ...deserializeNarrativeStore(obj.state),
          ...deserializeSoundStore(obj.state),
        },
        version: obj.version,
      };

      console.log('base', obj);
      console.log('dese', store);

      return store;
    },
  })
);
