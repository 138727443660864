import React from 'react';
import { motion } from 'framer-motion';

interface IProps {
  phase2Progress: number;
  isFlashing: boolean;
}

const Handle = ({ phase2Progress, isFlashing }: IProps) => (
  <motion.span
    id="handle"
    style={{
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: 'White',
      zIndex: 10,
      width: '20px',
      height: '20px',
      top: '-8px',
      transform: 'translateX(-10px)',
    }}
    animate={{
      left: `${Math.min(phase2Progress, 99.5)}%`,
    }}
    transition={{
      duration: 1,
      type: 'tween',
    }}
  >
    {isFlashing && (
      <motion.span
        style={{
          position: 'relative',
          display: 'block',
          backgroundColor: 'FireBrick',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
        }}
        animate={{
          scale: [0.2, 2],
          opacity: [0, 0.6],
        }}
        transition={{
          repeat: Infinity,
        }}
      />
    )}
  </motion.span>
);

export default Handle;
