import React from 'react';
import { useStore } from '../../store';
import { Table } from './table';

export const GameStateReporter = () => {
  const gameState = useStore(s => s.gameState);
  const recordPlayerChoice = useStore(s => s.recordPlayerChoice);
  const recordTeamChoice = useStore(s => s.recordTeamChoice);

  return (
    <>
      current incident: {gameState.currentIncident().id} -{' '}
      {gameState.currentIncident().applicableSolutions.join(', ')}
      <Table
        cols={['id', 'displayName', 'recordedSolution']}
        rows={gameState.playersIncidentReport(gameState.currentIncident().id)}
      />
    </>
  );
};
