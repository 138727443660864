import { FC, Suspense, useEffect, useState, ReactElement } from "react";
import buildAssetPath from "../utils/buildAssetPath";
import { BoardCanvas } from "./comps/board-canvas";
import { BoardFrame } from "./comps/board-frame";
import { DevTools } from "./comps/dev-tools";
import { useStore } from "./store";
import getLedspClient from "../utils/get-ledsp-client";

export const App: FC<AppProps> = (props) => {
  const [isStoreHydrated, setIsStoreHydrated] = useState(false);

  useEffect(() => {
    useStore.persist.setOptions({
      name: `fireballs-${props.gamePlayInfoId}`,
    });

    useStore.persist.rehydrate().then(() => setIsStoreHydrated(true));
  }, [props.gamePlayInfoId]);

  if (!isStoreHydrated) return <span>Hydrating store....</span>;

  return (
    <Game
      initializer={
        <Initializer
          gamePlayInfoId={props.gamePlayInfoId}
          observer={props.observer}
        />
      }
    />
  );
};

type AppProps = {
  readonly gamePlayInfoId: string;
  readonly observer?: boolean;
};

const Game: FC<{ initializer: ReactElement }> = (props) => {
  const isInitialized = useStore(({ isInitialized }) => isInitialized);
  const hasHydrated = useStore(({ hasHydrated }) => hasHydrated);

  if (!isInitialized) return props.initializer;
  if (!hasHydrated) return <span>Loading Game...</span>;

  return (
    <Suspense fallback={<LoadingScreen />}>
      <BoardFrame>
        <BoardCanvas />
      </BoardFrame>
      <DevTools />
      <QuanticoFont />
    </Suspense>
  );
};

const Initializer: FC<{ gamePlayInfoId: string; observer?: boolean }> = (
  props
) => {
  const init = useStore(({ init }) => init);

  const [error, setError] = useState<any>(null);

  useEffect(() => {
    console.log(`»» initializing game: «${props.gamePlayInfoId}»`);

    getLedspClient()
      .gamePlayInfo()
      .then((gamePlayInfo) => {
        if (props.observer)
          window.location.href = gamePlayInfo.settings.configuration.returnPath;
        init({
          assetsBaseUrl: buildAssetPath(
            "/" + (gamePlayInfo.settings.configuration.playOptions.lang || "en")
          ),
          ...gamePlayInfo,
        });
      })
      .catch(setError);
  }, [props.gamePlayInfoId]);

  return (
    <>
      {!props.gamePlayInfoId && <span>Missing game play info identifier!</span>}
      {props.gamePlayInfoId && error && <span>{error.message}</span>}
      {!error && <span>Initializing game...</span>}
    </>
  );
};

const LoadingScreen = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: "black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Quantico",
    }}
  >
    <div>THE GAME IS LOADING...</div>
  </div>
);

const QuanticoFont = () => {
  const url = useStore((s) => s.assetUrl);
  const styleInnerText = `
    @font-face {
      font-family: 'Quantico';
      src: url('${url(
        "/assets/fonts/Quantico-Bold.woff?family=Quantico-Bold"
      )}');
  `;

  return <style>{styleInnerText}</style>;
};
