import { StoreSlice } from '../utils/store-slice';
import { ILightDimmerStore } from '../interfaces/light-dimmer-store';

export const createLightDimmerStore: StoreSlice<ILightDimmerStore> = (
  set,
  get
) => ({
  isLightOn: true,
  switchLightsOff: () => {
    console.log(`»» lights switched off`);
    set({ isLightOn: false });
  },
  switchLightsOn: () => {
    console.log(`»» lights switched on`);
    set({ isLightOn: true });
  },
});
