/* eslint-disable indent */
import React, { FC } from 'react';
import * as THREE from 'three';
import { Video } from './video';
import { Cover } from './cover';
import { FullTerminal, SmallTerminal } from './terminal';
import { Incident } from './incident';
import { FullNarrative, SmallNarrative } from './narrative';
import { SquareImage } from './narrative/square-image';
import { FullTerminalSolo } from './terminal/full-terminal-solo';

const FULL = new THREE.Vector3(16 / 9, 1, 0);
const SMALL = new THREE.Vector3(0.77, 1, 0);

export const Comic: React.FC<{ type: string; name?: string }> = props => {
  const name = props.name || 'def';

  switch (props.type) {
    case 'cover':
      return <Cover />;

    case 'video':
      return <Video name={name} />;

    case 'small:terminal':
      return <SmallTerminal name={name} />;
    case 'large:terminal':
      return <FullTerminal name={name} />;
    case 'large_solo:terminal':
      return <FullTerminalSolo name={name} />;

    case 'small:narrative':
      return <SmallNarrative name={name} />;
    case 'large:narrative':
      return <FullNarrative name={name} />;

    case 'square:image':
      return <SquareImage />;

    case 'question':
      return <Incident name={name} />;
    case 'choice':
      return <Full />;

    default:
      return <Small />;
  }
};

const Small: FC = () => (
  <mesh castShadow receiveShadow>
    <boxGeometry args={SMALL.toArray()} />
    <meshPhongMaterial color={'pink'} />
  </mesh>
);

const Full: FC = () => (
  <mesh castShadow receiveShadow>
    <boxGeometry args={FULL.toArray()} />
    <meshPhongMaterial color={'blue'} transparent={true} />
  </mesh>
);
