import React from 'react';
import { useTexture } from '@react-three/drei';
import { FULL } from '../utils';

export const VideoThumbnail: React.FC<{ thumb: string }> = props => {
  const thumbnail = useTexture(props.thumb);
  return (
    <mesh castShadow receiveShadow position={[0, 0, 0.001]}>
      <boxGeometry args={FULL.clone().multiplyScalar(0.93).toArray()} />
      <meshPhongMaterial map={thumbnail} transparent={true} />
    </mesh>
  );
};
