import { useStore } from "../../../store";
import React, { Suspense } from "react";
import { VideoFrame } from "./frame";
import { VideoProjection } from "./projection";
import { VideoThumbnail } from "./thumbnail";

export const Video: React.FC<{ name: string }> = (props) => {
  const channel = useStore((s) => s.videoChannels).get(props.name);

  if (!channel) {
    console.log("cannot load channel", props.name);
    return null;
  }

  return (
    <group>
      <Suspense fallback={null}>
        {!channel.isPlaying && (
          <VideoThumbnail thumb={channel.currentThumbPath} />
        )}
        {channel.isPlaying && <VideoPlayer videoPath={channel.videoPath} />}
      </Suspense>
      <VideoFrame />
    </group>
  );
};

const VideoPlayer: React.FC<{ videoPath: string }> = (props) => {
  const video = useStore((s) => s.videoLoader).read(props.videoPath);
  return <VideoProjection videoTexture={video} />;
};
