import Immutable from "immutable";
import { IncidentState } from "./incident-state";
import {
  IGameState,
  IncidentStatement,
  PlayerIncidentReport,
  PlayerInfo,
} from "./interfaces";
import { PlayerState } from "./player-state";

import transit from "transit-immutable-js";

export class GameState
  extends Immutable.Record(
    {
      players: Immutable.Map<string, PlayerState>(),
      incidents: Immutable.OrderedMap<string, IncidentState>(),
      lastOccurredIncident: "",
    },
    "GameState"
  )
  implements IGameState
{
  static Null() {
    return new GameState();
  }

  static Init(players: PlayerInfo[]) {
    return players
      .reduce((state, player) => state.addPlayer(player), new GameState())
      .incidentOccurred("A1", ["1", "2", "3", "4"]);
  }

  static decode(raw: any) {
    return transit
      .withRecords([GameState, PlayerState, IncidentState])
      .fromJSON(raw);
  }

  static encode(target: GameState) {
    return transit
      .withRecords([GameState, PlayerState, IncidentState])
      .toJSON(target);
  }

  addPlayer(info: PlayerInfo) {
    return this.update("players", (v) =>
      v.set(info.id, PlayerState.fromPlayerInfo(info))
    );
  }

  incidentOccurred(id: string, applicableSolutions: string[]) {
    const incident = IncidentState.Init(id, applicableSolutions);

    return this.update("incidents", (v) => v.set(id, incident)).set(
      "lastOccurredIncident",
      id
    );
  }

  playersIncidentReport(incident: string): PlayerIncidentReport[] {
    return this.players
      .map((p) => p.toIncidentReport(incident))
      .toList()
      .toArray();
  }

  incidentByOrder(index: number): IncidentStatement {
    return this.incidents
      .toList()
      .get(index, IncidentState.Null())
      .toIncidentStatement();
  }

  incident(id: string): IncidentStatement {
    return this.incidents.get(id, IncidentState.Null()).toIncidentStatement();
  }

  incidentHasBeenSolved(id: string): boolean {
    return this.incidents.get(id, IncidentState.Null()).hasBeenSolved();
  }

  currentIncident(): IncidentStatement {
    return this.incidents
      .get(this.lastOccurredIncident, IncidentState.Null())
      .toIncidentStatement();
  }

  canTeamApplySolutionTo(incident: string) {
    return (
      this.players.every((p) => p.hasSolutionBeenRecorded(incident)) &&
      !this.incidentHasBeenSolved(incident)
    );
  }

  appliedSolution(incident: string): string {
    return this.incidents.get(incident, IncidentState.Null()).appliedSolution;
  }

  appliedSolutionAt(index: number): string {
    return this.incidents.toList().get(index, IncidentState.Null())
      .appliedSolution;
  }

  readPlayerSolutionAt(playerId: string, index: number): string {
    const incident = this.incidents.toList().get(index, IncidentState.Null());
    return this.players
      .get(playerId, PlayerState.Null())
      .readSolution(incident.id);
  }

  displaySolution(incident: string, solutionIndex: number) {
    return this.update("incidents", (v) =>
      v.update(incident, (i) => i.displaySolution(solutionIndex))
    );
  }

  recordPlayerSolution(playerId: string, incident: string, solution: string) {
    return this.update("players", (v) =>
      v.update(playerId, (p) => p.recordSolution(incident, solution))
    );
  }

  recordTeamSolution(incident: string, solution: string) {
    const solutionIndex = solution[solution.length - 1];

    const v = this.update("incidents", (v) =>
      v.update(incident, (i) => i.applySolution(solution))
    );

    if (incident.startsWith("A"))
      return v.incidentOccurred(`B${solutionIndex}`, ["1", "2", "3", "4"]);
    return v;
  }

  prepareIncidentAfter(incident: string) {
    if (!this.incidentHasBeenSolved(incident)) return this;

    const solution = this.appliedSolution(incident);
    const solutionIndex = solution[solution.length - 1];

    if (incident.startsWith("A"))
      return this.incidentOccurred(`B${solutionIndex}`, ["1", "2", "3", "4"]);

    return this;
  }
}
