import React from 'react';
import { useStore } from '../../store';
import { NavigationButtons } from '../board-frame-navigation-buttons';

export const ShootNavigation = () => {
  const nextShootIndex = useStore(s => s.nextShootIndex);
  const prevShootIndex = useStore(s => s.prevShootIndex);
  const shoot = useStore(s => s.shoot);
  const isNavigationVisible = useStore(s => s.isNavigationVisible);

  return (
    <NavigationButtons
      isVisible={isNavigationVisible}
      canGoBack={true}
      canGoForward={true}
      goBack={() => shoot(prevShootIndex)}
      goForward={() => shoot(nextShootIndex)}
    />
  );
};
