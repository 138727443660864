import React from 'react';

const FinishCircle = () => (
  <span
    style={{
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      border: '2px solid white',
      position: 'absolute',
      backgroundColor: 'black',
      right: '-4px',
    }}
  />
);

export default FinishCircle;
