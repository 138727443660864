import { StoreSlice } from '../utils/store-slice';
import { IScriptStore } from '../interfaces/script-store';
import { IScreenPlayStore } from '../interfaces/screen-play-store';
import { ISoundtrackStore } from '../interfaces/soundtrack-store';
import { GetState, SetState } from 'zustand';
import { scenes } from '../../scenes';

export const createScriptStore: StoreSlice<
  IScriptStore,
  IScreenPlayStore & ISoundtrackStore
> = (set, get) => ({
  isStoryStarted: false,
  nextSceneIndex: 'cover',
  currentScene: '',
  playScene: name => {
    const scene = scenes.get(name);
    if (!scene)
      console.warn('cannot fine scene', name, 'in', [...scenes.keys()]);

    console.log('playing scene', name);
    set({ currentScene: name, isStoryStarted: true });
    scene.play(set, get);
  },
});

export type SceneInfo<T extends object = any> = {
  play(set: SetState<T>, get: GetState<T>): void;
};
