import getLedspClient from "../../utils/get-ledsp-client";
import { Sequence } from "../scene-control/sequence";
import { SceneInfo } from "../store/factories/create-script-store";
import { IAssetsLoaderStore } from "../store/interfaces/assets-loader-store";
import { IBasicGameStore } from "../store/interfaces/basic-game-store";
import { ICommunicationsStore } from "../store/interfaces/communications-store";
import { INarrativeStore } from "../store/interfaces/narrative-store";
import { IPlayerCameraStore } from "../store/interfaces/player-camera-store";
import { IScreenPlayStore } from "../store/interfaces/screen-play-store";
import { IScriptStore } from "../store/interfaces/script-store";
import { ISequencePlayerStore } from "../store/interfaces/sequence-player-store";
import { ISoundtrackStore } from "../store/interfaces/soundtrack-store";
import { IVideoMixerStore } from "../store/interfaces/video-mixer-store";
import { shootBuilder as s } from "./utils";

export const GameOver: SceneInfo<
  IScreenPlayStore &
    ISoundtrackStore &
    IVideoMixerStore &
    ICommunicationsStore &
    IPlayerCameraStore &
    ISequencePlayerStore &
    INarrativeStore &
    IScriptStore &
    IBasicGameStore &
    IAssetsLoaderStore
> = {
  play(set, get) {
    getLedspClient().sendGameProgressEvent({
      eventType: "game-ended",
      gameId: get().initProps.gameId,
      playerId: get().initProps.playerId,
      teamId: get().initProps.settings.configuration.teamId,
      cleared: false,
    });

    const videoDir = `finale_game_over`;

    get().loadVideoChannel("5", {
      videoPath: get().assetUrl(`/assets/videos/_/${videoDir}/video.webm`),
      openingThumbPath: get().assetUrl(`/assets/videos/_/${videoDir}/in.avif`),
      closingThumbPath: get().assetUrl(`/assets/videos/_/${videoDir}/out.avif`),
    });

    get().assignNarrativeChannel("4", {
      message: `finale_game-over`,
      picture: "team_intro",
    });

    get().playSequence(
      Sequence.fromStages([
        (next) => {
          get().playSoundtrack(`finale-game-over`);
          get().replaceShootList([
            s.addComics(["4:0"]).make(),
            s.addComics(["4:0"]).inside().make(),
            s.addComics(["4:0"]).make(),
            s.addComics(["4:1"]).make(),
          ]);
          get().shoot(0);
          setTimeout(next, 2500);
        },
        (next) => {
          get().shoot(1);
          get().turnUIOn();
          get().turnNavigationOff();
          get().setContinue(() => {
            get().shoot(get().nextShootIndex);
            get().setContinue(() => {
              if (get().isGameEnded) {
                window.location.href =
                  get().initProps.settings.configuration.returnPath;
              }
            }, "End game");
          }, "Go to feedback");
          next();
        },
      ])
    );
  },
};
