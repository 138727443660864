import Immutable from "immutable"
import { Closeness, Shoot } from "./shoot"

export class ShootBuilder {

  static init() {
    return new ShootBuilder()
  }

  constructor(
    private readonly comics = Immutable.List<string>(),
    private readonly closeness: Closeness = Closeness.NEAR,
    private readonly namer: (relativeName: string) => string = i => i
  ) { }

  reset() {
    return new ShootBuilder()
  }

  addComics(comics: string[]) {
    return new ShootBuilder(
      this.comics.concat(comics),
      this.closeness,
      this.namer
    )
  }

  near() {
    return new ShootBuilder(
      this.comics,
      Closeness.NEAR,
      this.namer
    )
  }

  far() {
    return new ShootBuilder(
      this.comics,
      Closeness.FAR,
      this.namer
    )
  }

  inside() {
    return new ShootBuilder(
      this.comics,
      Closeness.INSIDE,
      this.namer
    )
  }

  setNamer(namer: (relativeName: string) => string) {
    return new ShootBuilder(
      this.comics,
      this.closeness,
      namer
    )
  }

  make() {
    return new Shoot(
      this.comics.map(comic => this.namer(comic)),
      this.closeness
    )
  }

}
