import { useEffect, useState } from 'react';
import Immutable from 'immutable';
import { TerminalCommunication } from './terminal-communication';

export function useTerminal(
  communication: TerminalCommunication,
  onCommunicationEnded = () => undefined
) {
  const [textChunks, setTextChunks] = useState(Immutable.List<string>());
  const [cursor, setCursor] = useState('_');
  const [status, setStatus] = useState<'idle' | 'writing' | 'ended'>('idle');

  const [typewriter] = useState(() =>
    communication.toTypeWriter({
      onAddCharacter: (character: string) => {
        setTextChunks(s => s.push(character));
      },
      onRemoveCharacter: () => {
        setTextChunks(s => s.pop());
      },
      onCommunicationEnded: () => {
        setStatus('ended');
        onCommunicationEnded();
      },
    })
  );

  useEffect(() => {
    setTextChunks(s => s.clear());
    const cursorInterval = setInterval(
      () => setCursor(s => (s === '_' ? ' ' : '_')),
      500
    );

    return () => {
      typewriter.stop();
      clearInterval(cursorInterval);
    };
  }, [communication]);

  return {
    start() {
      setStatus('writing');
      typewriter.start();
    },
    text: textChunks.join('').concat((status === 'writing' && cursor) || ''),
  };
}
