import React from 'react';
import { motion } from 'framer-motion';

interface IProps {
  progress: number;
  isPhaseEnded: boolean;
}

const Phase1ExpiredBar = ({ progress, isPhaseEnded }: IProps) => (
  <motion.span
    id="stop-1-expired-bar"
    style={{
      height: '100%',
      display: 'inline-block',
      backgroundColor: isPhaseEnded ? '#ffdd17' : 'FireBrick',
    }}
    animate={{
      width: `${Math.max(0, progress - 50)}%`,
    }}
  />
);

export default Phase1ExpiredBar;
