import React from "react";
import { Text } from "@react-three/drei";
import * as THREE from "three";
import { FULL } from "../utils";
import { SquareImage } from "./square-image";
import { FullNarrativeFrame } from "./full-narrative-frame";
import { useStore } from "../../../store";

const IMAGE_ROTATION = new THREE.Euler(0, 0, -0.1);

export const FullNarrative: React.FC<FullNarrativeProps> = (props) => {
  const url = useStore((s) => s.assetUrl);
  const loader = useStore((s) => s.narrativeLoader);
  const narrative = useStore((s) => s.narrativeChannels).get(props.name);

  const text = (narrative && loader.read(narrative.message)) || "---";

  return (
    <>
      <Text
        color="black"
        maxWidth={FULL.x * 0.48}
        lineHeight={1.3}
        letterSpacing={0.05}
        fontSize={0.03}
        font={url("/assets/fonts/Caveat-VariableFont_wght.woff")}
        anchorX={"left"}
        position={[-0.8, 0, 0.01]}
      >
        {text.toUpperCase()}
      </Text>
      <SquareImage
        position={[0.5, 0, 0.1]}
        rotation={IMAGE_ROTATION}
        scale={[0.8, 0.8, 1]}
      />
      <FullNarrativeFrame />
    </>
  );
};

export type FullNarrativeProps = {
  name: string;
};
