import React, { FC } from "react";
import { useTexture } from "@react-three/drei";
import { GroupProps } from "@react-three/fiber";
import { SQUARE } from "../utils";
import { useStore } from "../../../store";

export const SquareImage: React.FC<GroupProps> = (props) => (
  <group {...props}>
    <SquareImagePicture />
    <SquareImageFrame />
  </group>
);

export const SquareImageFrame: FC = () => {
  const url = useStore((s) => s.assetUrl);
  const texture = useTexture(url("/assets/textures/ui/c_image_square.svg"));

  return (
    <mesh castShadow receiveShadow>
      <boxGeometry args={SQUARE.toArray()} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};

export const SquareImagePicture = () => {
  const url = useStore((s) => s.assetUrl);
  const texture = useTexture(
    url("/assets/textures/narratives/team_intro.avif")
  );

  return (
    <mesh position={[-0.02, 0, -0.01]} castShadow receiveShadow>
      <boxGeometry args={[0.92, 0.95, 0]} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};
