import Immutable from 'immutable';
import {
  IOnStageEndedFn,
  ISequence,
  ISequencePlayer,
} from './sequence.interface';

export class SequencePlayer implements ISequencePlayer {
  static Init(onSequenceEnded?: (sequence: ISequence) => void) {
    return new SequencePlayer(onSequenceEnded);
  }

  private data = SequencePlayerState.Init();

  private onStageEnded: IOnStageEndedFn = (sequence, stageEnded, nextStage) => {
    // console.log(
    //   '»» onStageEnded',
    //   sequence.toString(),
    //   this.data.isPlaying(sequence)
    // );

    if (!this.data.isPlaying(sequence)) return this.onSequenceEnded(sequence);

    this.data = this.data.sequenceStageEnded(sequence, stageEnded);

    if (!nextStage) return this.onSequenceEnded(sequence);

    setTimeout(() => this.play(sequence, nextStage), 0);
  };

  constructor(
    private readonly onSequenceEnded = (sequence: ISequence) => undefined
  ) {}

  play(sequence: ISequence, stage: number = 0) {
    this.data = this.data.sequencePlayed(sequence, stage);
    sequence.playStage(stage, this.onStageEnded);
    return this;
  }

  stopAll() {
    this.data = this.data.allSequencesWereStopped();
    this.data.playingSequences.forEach(s => this.onSequenceEnded(s));
    return this;
  }

  stop(sequence: ISequence) {
    this.data = this.data.sequenceStopped(sequence);
    this.onSequenceEnded(sequence);
    return this;
  }
}

class SequencePlayerState extends Immutable.Record(
  {
    playingSequences: Immutable.Set<ISequence>(),
    cursors: Immutable.Map<ISequence, number>(),
  },
  'SequencePlayerState'
) {
  static Init() {
    return new SequencePlayerState();
  }

  sequencePlayed(sequence: ISequence, stage: number) {
    // console.log('»» sequencePlayed', sequence.toString(), stage);
    return this.update('playingSequences', v => v.add(sequence)).update(
      'cursors',
      v => v.set(sequence, stage)
    );
  }

  sequenceStageEnded(sequence: ISequence, stageIndex: number) {
    // console.log('»» sequenceStageEnded', sequence.toString(), stageIndex);
    return this.update('cursors', v => v.set(sequence, stageIndex));
  }

  sequenceStopped(sequence: ISequence) {
    // console.log('»» sequenceStopped', sequence.toString());

    return this.update('playingSequences', v => v.remove(sequence)).update(
      'cursors',
      v => v.set(sequence, 0)
    );
  }

  allSequencesWereStopped() {
    // console.log('»» allSequencesWereStopped');

    return this.update('playingSequences', v => v.clear()).update(
      'cursors',
      v => v.clear()
    );
  }

  isPlaying(sequence: ISequence) {
    return this.playingSequences.includes(sequence);
  }

  cursor(sequence: ISequence) {
    return this.cursors.get(sequence, 0);
  }
}
