import Immutable from 'immutable';
import { IncidentStatement } from './interfaces';

export class IncidentState extends Immutable.Record(
  {
    id: '--null--',
    applicableSolutions: Immutable.List<string>(),
    appliedSolution: '--null--',
    displayedSolutionIndex: 0,
  },
  'IncidentState'
) {
  static Null() {
    return new IncidentState();
  }

  static Init(id: string, applicableSolutions: string[]) {
    return new IncidentState({
      id,
      applicableSolutions: Immutable.List(applicableSolutions),
    });
  }

  isNull() {
    return this.id === '--null--';
  }

  hasBeenSolved() {
    return this.isNull() || this.appliedSolution !== '--null--';
  }

  applySolution(solution: string) {
    return this.set('appliedSolution', solution);
  }

  displaySolution(index: number) {
    return this.set(
      'displayedSolutionIndex',
      index % this.applicableSolutions.count()
    );
  }

  toIncidentStatement(): IncidentStatement {
    return {
      isNull: this.isNull(),
      id: this.id,
      applicableSolutions: this.applicableSolutions.toArray(),
      applicableSolutionsCount: this.applicableSolutions.count(),
      displayedSolution: this.applicableSolutions.get(
        this.displayedSolutionIndex
      ),
      displayedSolutionIndex: this.displayedSolutionIndex,
    };
  }
}
