import Immutable from 'immutable';
import {
  CommunicationInfo,
  ICommunicationStatus,
} from '../../store/interfaces/communications-store';

import transit from 'transit-immutable-js';

export class CommunicationStatus
  extends Immutable.Record(
    {
      terminalId: '',
      message: '--empty--',
      claim: '--empty--',
      isStarted: false,
      isEnded: false,
    },
    'CommunicationStatus'
  )
  implements ICommunicationStatus
{
  static decode(raw: any) {
    return transit.withRecords([CommunicationStatus]).fromJSON(raw);
  }

  static encode(target: CommunicationStatus) {
    return transit.withRecords([CommunicationStatus]).toJSON(target);
  }

  static Empty() {
    return new CommunicationStatus();
  }

  static fromCommunicationInfo(info: CommunicationInfo) {
    return new CommunicationStatus({ ...info });
  }

  linkTerminal(terminalId: string) {
    return this.set('terminalId', terminalId);
  }

  start() {
    return this.set('isStarted', true);
  }

  end() {
    return this.set('isEnded', true);
  }
}
