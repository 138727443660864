/* eslint-disable indent */
import Immutable, { ValueObject } from 'immutable';
import * as THREE from 'three';
import { Box3, Euler } from 'three';

export class Shoot implements ValueObject {
  static encode(target: Shoot) {
    return {
      comics: target.comics.toArray(),
      closeness: target.closeness,
    };
  }

  static decode(raw: any) {
    return new Shoot(raw.comics, raw.closeness);
  }

  private readonly comics: Immutable.List<string>;

  constructor(
    comics: Iterable<string>,
    public readonly closeness: Closeness = Closeness.NEAR
  ) {
    this.comics = Immutable.List(comics);
  }

  contains(comic: string) {
    return this.comics.contains(comic);
  }

  toCameraPosition(
    scene: THREE.Scene,
    positionFromBox3: (target: Box3, offset: number) => THREE.Vector3
  ) {
    const target = this.comics
      .map(t => scene.getObjectByName(t))
      .filter(obj => obj)
      .reduce((box, obj) => box.expandByObject(obj), new THREE.Box3());

    return positionFromBox3(target, this.closeness);
  }

  toCameraOrientation() {
    if (this.closeness === Closeness.FAR) return new Euler(-0.05, -0.03, -0.05);
    return new Euler(0, 0, 0);
  }

  toString() {
    return `${this.comics.join(',')}-(${this.closeness})`;
  }

  equals(other: unknown): boolean {
    return (
      other instanceof Shoot &&
      other.comics.equals(this.comics) &&
      other.closeness === this.closeness
    );
  }

  hashCode(): number {
    return 0;
  }

  static cameraPositionFromBox3 =
    (cameraFov: number) =>
    (box: THREE.Box3, offset: number = 1) => {
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = cameraFov * (Math.PI / 180);
      const z = Math.abs((maxDim / 4) * Math.tan(fov * 2)) * offset - 0.3;

      return new THREE.Vector3(center.x, center.y, z);
    };
}

export enum Closeness {
  INSIDE = 1,
  NEAR = 1.5,
  FAR = 3,
}
