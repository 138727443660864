import { Sequence } from '../scene-control/sequence';
import { SceneInfo } from '../store/factories/create-script-store';
import { ICommunicationsStore } from '../store/interfaces/communications-store';
import { IPlayerCameraStore } from '../store/interfaces/player-camera-store';
import { IScreenPlayStore } from '../store/interfaces/screen-play-store';
import { IScriptStore } from '../store/interfaces/script-store';
import { ISequencePlayerStore } from '../store/interfaces/sequence-player-store';
import { ISoundtrackStore } from '../store/interfaces/soundtrack-store';
import { IVideoMixerStore } from '../store/interfaces/video-mixer-store';
import { shootBuilder as s } from './utils';

type Store = IScreenPlayStore &
  ISoundtrackStore &
  IVideoMixerStore &
  ICommunicationsStore &
  IPlayerCameraStore &
  IScriptStore &
  ISequencePlayerStore;

export const AThreatToM1: SceneInfo<Store> = {
  play(set, get) {
    set({ nextSceneIndex: 'the-mission' });
    get().setSuperFocus('normal');

    get().playSequence(
      Sequence.fromStages(
        [
          cb => {
            get().playSoundtrack('a-threat-to-m1');
            get().replaceShootList([
              s.addComics(['0:0']).far().make(),
              s.addComics(['0:1']).make(),
              s.addComics(['0:1']).inside().make(),
              s.addComics(['0:1']).make(),
            ]);
            get().shoot(1);
            setTimeout(cb, 2500);
          },
          cb => {
            get().shoot(2);
            get().onVideoEnded('1', cb);
          },
          cb => {
            get().shoot(3);
            setTimeout(cb, 2000);
          },
          cb => {
            get().playScene('the-mission');
            cb();
          },
        ],
        'AThreatToM1'
      )
    );
  },
};
