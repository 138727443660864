import useEvent from "@react-hook/event";
import { useStore } from "../../store";

export const SceneController = () => {

  const nextShootIndex = useStore(s => s.nextShootIndex);
  const prevShootIndex = useStore(s => s.prevShootIndex);
  const shoot = useStore(s => s.shoot);
  // Const prevShoot = useStore(s => s.prevShoot);
  // const nextScene = useStore(s => s.nextScene);
  // const prevScene = useStore(s => s.prevScene);

  useEvent(window, 'keydown', ev => {
    switch (ev.key) {
    case 'ArrowRight': shoot(nextShootIndex); break;
    case 'ArrowLeft': shoot(prevShootIndex); break;
      // Case 'ArrowDown': nextScene(); break;
      // case 'ArrowUp': prevScene(); break;
    }
  })

  return null;
}
