import { motion } from 'framer-motion';
import React from 'react';
import { useStore } from '../../store';
import { useSound } from 'use-sound';

export const NextButton = () => {
  const goOn = useStore(s => s.continue);
  const continueLabel = useStore(s => s.continueLabel);
  const url: (path: string) => string = useStore(s => s.assetUrl);
  const [playClick] = useSound(url('/assets/sounds/_/continue-click.opus'), {
    volume: 0.2,
  });

  return (
    <motion.button
      whileHover={{ scale: 1.02, transition: { duration: 0.1 } }}
      whileTap={{
        scale: 0.98,
        filter: 'saturate(0) brightness(0.5)',
        transition: { duration: 0.01 },
      }}
      onClick={() => {
        playClick();
        goOn();
      }}
      style={{
        width: '100%',
        height: '100%',
        border: '2px solid White',
        backgroundColor: '#1F2C39',
        color: 'White',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px',
        fontFamily: 'Caveat',
        filter: 'saturate(1) brightness(1)',
      }}
    >
      <p style={{ fontSize: '16pt' }}>{continueLabel}</p>

      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            display: 'inline-block',
            width: '12px',
            height: '12px',
            position: 'relative',
            backgroundColor: 'White',
            borderRadius: '50%',
            marginRight: '6px',
          }}
        />
        <span
          style={{
            display: 'inline-block',
            width: 0,
            height: 0,
            borderTop: '16px solid transparent',
            borderBottom: '16px solid transparent',
            borderLeft: '26px solid White',
          }}
        />
      </span>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
        `}
      </style>
    </motion.button>
  );
};
