import React, { useMemo, useRef, useState } from 'react';
import { MotionCanvas } from 'framer-motion-3d';
import { ComicPageOnPaper } from '../comic-page';
import { styles } from './styles';
import { useStore } from '../../store';
import { PlayerCamera } from '../player-camera';
import { motion as m } from 'framer-motion-3d';
import useEvent from '@react-hook/event';
import { Preloader } from '../../preloader';
import { AdaptiveDpr } from '@react-three/drei';

export const BoardCanvas = () => {
  const debug = useStore(s => s.areVisualGuidesVisible);
  const s = useMemo(() => styles({ debug }), [debug]);

  const isLightOn = useStore(s => s.isLightOn);
  const setSuperFocus = useStore(s => s.setSuperFocus);
  const [key, setKey] = useState('--');

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEvent(window, 'resize', e => {
    setKey(`${window.innerWidth}:${window.innerHeight}`);
  });

  // useEvent(canvasRef, 'mousedown', () => setSuperFocus('close'));
  // useEvent(canvasRef, 'mouseup', () => setSuperFocus('normal'));
  // useEvent(canvasRef, 'wheel', e => {
  //   if (e.deltaY < 0) setSuperFocus('normal');
  //   if (e.deltaY > 0) setSuperFocus('eagle');
  // });

  return (
    <MotionCanvas key={key} ref={canvasRef} shadows style={s.main}>
      <color attach="background" args={['black']} />
      <ambientLight intensity={isLightOn ? 0.01 : 0.01} />
      {/* <m.spotLight
        intensity={isLightOn ? 0.05 : 0}
        color="red"
        position={[0, 0, -10]}
      /> */}

      <ComicPageOnPaper position={[0, 0, -0.5]} />
      <PlayerCamera />

      <Preloader />
      <AdaptiveDpr />
    </MotionCanvas>
  );
};
