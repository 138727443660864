import { GroupProps } from '@react-three/fiber';
import React from 'react';
import { ComicPage } from './comic-page';
import { Paper } from './paper';
export const ComicPageOnPaper: React.FC<GroupProps> = props => (
  <group {...props}>
    <ComicPage />
    <Paper position={[4.5, -8, -0.02]} />
  </group>
);
