import Immutable from "immutable";
import memoizee from "memoizee";
import { Howl } from "howler";
import { StoreSlice } from "../utils/store-slice";
import {
  ISoundtrackStore,
  SoundtrackInfo,
} from "../interfaces/soundtrack-store";
import { SoundtrackMixer } from "../../scene-control/soundtrack-mixer";
import { IAssetsLoaderStore } from "../interfaces/assets-loader-store";
import { fromHowlSprite } from "../../scene-control/audio-player.interface";

export const createSoundtrackStore: StoreSlice<
  ISoundtrackStore,
  IAssetsLoaderStore
> = (set, get) => ({
  playSoundtrack: (name: string) => {
    console.log(`»» playing soundtrack «${name}»`);
    const info = SOUNDTRACKS.get(name);
    const player = info.prepare(get().assetUrl);
    mixer.play(player);
  },

  stopSoundtrack() {
    mixer.stop();
  },
});

const mixer = SoundtrackMixer.setup();

const loadSoundtrack = memoizee(
  (url: string, volume: number = 0.5) =>
    new Howl({
      src: [url],
      volume: volume,
      loop: true,
    })
);

const loadSoundtrackSlice = memoizee(
  (url: string, from: number, to: number, volume: number = 0.5) =>
    fromHowlSprite(
      new Howl({
        src: [url],
        volume: volume,
        loop: true,
        sprite: {
          main: [from, to],
        },
      }),
      "main"
    )
);

const SOUNDTRACKS = Immutable.Map<string, SoundtrackInfo>()
  .set("intro", {
    prepare: (url) =>
      loadSoundtrack(url("/assets/music/harry-and-grace-make-peace.opus")),
  })

  .set("a-threat-to-m1", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/main-title.opus"),
        (60 + 15) * 1000,
        (60 + 15 + 20) * 1000
      ),
  })

  .set("the-mission", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/main-title.opus"),
        (60 + 46 + 0.5) * 1000,
        (60 + 46 + 60) * 1000
      ),
  })

  .set("team", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/3.meet_team_ArmaSound_Armadillo.opus"),
        8 * 1000 + 891,
        9 * 1000 + 611
      ),
  })

  .set("first-choice", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/4.sceltaA_Death of MIR.opus"),
        0,
        36 * 1000
      ),
  })

  .set("first-choice-decision", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/4.sceltaA_Death of MIR.opus"),
        0 * 1000,
        52 * 1000,
        0
      ),
  })
  .set("first-choice-consequences-A", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/4.sceltaA_Death of MIR.opus"),
        35 * 1000 + 688,
        28 * 1000 + 922,
        0
      ),
  })
  .set("first-choice-consequences-B", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/6.decisionsC,D_Long Distance Goodbye Landing.opus"),
        (3 * 60 + 26) * 1000,
        (3 * 60 + 47) * 1000
      ),
  })
  .set("second-choice", {
    prepare: (url) =>
      loadSoundtrack(
        url("/assets/music/7.sceltaB_ArmSound_Underwater Simulation.opus")
      ),
  })
  .set("finale-good", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/9.final_good_ Launch.opus"),
        (4 * 60 + 45) * 1000,
        (10 * 60 + 0) * 1000
      ),
  })
  .set("finale-random", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/10.final_random_Long Distance Goodbye Landing.opus"),
        (0 * 60 + 0) * 1000,
        (0 * 60 + 22) * 1000
      ),
  })
  .set("finale-bad", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/11_ final_bad_game_over_Leaving.opus"),
        (1 * 60 + 56) * 1000,
        (10 * 60 + 0) * 1000
      ),
  })
  .set("finale-game-over", {
    prepare: (url) =>
      loadSoundtrackSlice(
        url("/assets/music/11_ final_bad_game_over_Leaving.opus"),
        (1 * 60 + 56) * 1000,
        (10 * 60 + 0) * 1000
      ),
  });
