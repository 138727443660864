import { Howl } from 'howler';

export interface IAudioPlayer {
  play(): number;
  fade(from: number, to: number, duration: number, soundId: number): void;
}

export function fromHowlSprite(howl: Howl, spriteName: string) {
  return {
    play() {
      return howl.play(spriteName);
    },
    // eslint-disable-next-line max-params
    fade(from: number, to: number, duration: number, soundId: number) {
      return howl.fade(from, to, duration, soundId);
    },
  };
}
