import getLedspClient from "../../utils/get-ledsp-client";
import { Sequence } from "../scene-control/sequence";
import { SceneInfo } from "../store/factories/create-script-store";
import { IBasicGameStore } from "../store/interfaces/basic-game-store";
import { ICommunicationsStore } from "../store/interfaces/communications-store";
import { IPlayerCameraStore } from "../store/interfaces/player-camera-store";
import { IScreenPlayStore } from "../store/interfaces/screen-play-store";
import { IScriptStore } from "../store/interfaces/script-store";
import { ISequencePlayerStore } from "../store/interfaces/sequence-player-store";
import { ISoundtrackStore } from "../store/interfaces/soundtrack-store";
import { ITimerStore } from "../store/interfaces/timer-store";
import { IVideoMixerStore } from "../store/interfaces/video-mixer-store";
import { shootBuilder as s } from "./utils";

export const TheMission: SceneInfo<
  IScreenPlayStore &
    ISoundtrackStore &
    IVideoMixerStore &
    ICommunicationsStore &
    IPlayerCameraStore &
    IScriptStore &
    ISequencePlayerStore &
    IBasicGameStore &
    ITimerStore
> = {
  play(set, get) {
    getLedspClient().sendGameProgressEvent({
      eventType: "game-stage-entered",
      gameId: get().initProps.gameId,
      playerId: get().initProps.playerId,
      teamId: get().initProps.settings.configuration.teamId,
      step: "Introduction",
      stage: "The mission",
    });
    set({ nextSceneIndex: "team" });

    get().setContinue(() => {
      get().endCommunication("1");
      if (get().isLastShoot) get().playScene(get().nextSceneIndex);
      else get().shoot(get().nextShootIndex);
    });

    get().playSequence(
      Sequence.fromStages([
        (next) => {
          get().replaceShootList([
            s.addComics(["0:0"]).far().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["0:1"]).inside().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["1:0"]).make(),
          ]);
          get().shoot(4);

          get().playSoundtrack("the-mission");

          setTimeout(next, 1500);
        },
        (next) => {
          get().turnUIOn();
          get().turnNavigationOn();

          !get().isTimerStarted && get().startTimer(Date.now());
          setTimeout(next, 1000);
        },
        (next) => {
          get().startCommunication("1", {
            message: "mission",
            claim: "mission-claim",
          });
          next();
        },
      ])
    );
  },
};
