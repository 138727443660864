import getLedspClient from "../../utils/get-ledsp-client";
import { Sequence } from "../scene-control/sequence";
import { SceneInfo } from "../store/factories/create-script-store";
import { IAssetsLoaderStore } from "../store/interfaces/assets-loader-store";
import { IBasicGameStore } from "../store/interfaces/basic-game-store";
import { ICommunicationsStore } from "../store/interfaces/communications-store";
import { INarrativeStore } from "../store/interfaces/narrative-store";
import { IPlayerCameraStore } from "../store/interfaces/player-camera-store";
import { IScreenPlayStore } from "../store/interfaces/screen-play-store";
import { IScriptStore } from "../store/interfaces/script-store";
import { ISequencePlayerStore } from "../store/interfaces/sequence-player-store";
import { ISoundtrackStore } from "../store/interfaces/soundtrack-store";
import { ITimerStore } from "../store/interfaces/timer-store";
import { IVideoMixerStore } from "../store/interfaces/video-mixer-store";
import { shootBuilder as s } from "./utils";

export const SecondChoiceConsequences: SceneInfo<
  IScreenPlayStore &
    ISoundtrackStore &
    IVideoMixerStore &
    ICommunicationsStore &
    IPlayerCameraStore &
    ISequencePlayerStore &
    INarrativeStore &
    IScriptStore &
    IBasicGameStore &
    IAssetsLoaderStore &
    ITimerStore
> = {
  play(set, get) {
    getLedspClient().sendGameProgressEvent({
      eventType: "game-stage-entered",
      gameId: get().initProps.gameId,
      playerId: get().initProps.playerId,
      teamId: get().initProps.settings.configuration.teamId,
      step: "Final results",
      stage: "Result",
    });
    get().gameClear();

    const incidentA = get().gameState.incidentByOrder(0);
    const solutionA = get().gameState.appliedSolution(incidentA.id);

    const incidentB = get().gameState.incidentByOrder(1);
    const solutionB = get().gameState.appliedSolution(incidentB.id);

    const finalSolution = `A${solutionA}_B${solutionB}`;
    const feedback = finalFeedback(finalSolution);

    const videoDir = `finale_${feedback}`;

    get().loadVideoChannel("5", {
      videoPath: get().assetUrl(`/assets/videos/_/${videoDir}/video.webm`),
      openingThumbPath: get().assetUrl(`/assets/videos/_/${videoDir}/in.avif`),
      closingThumbPath: get().assetUrl(`/assets/videos/_/${videoDir}/out.avif`),
    });

    get().playSequence(
      Sequence.fromStages([
        (next) => {
          get().playSoundtrack(`finale-${feedback}`);
          get().replaceShootList([
            s.addComics(["0:0"]).far().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["0:1"]).inside().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["1:0"]).make(),

            s.addComics(["1:1"]).make(),
            s.addComics(["1:2"]).far().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:3"]).inside().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:4"]).make(),
            s.addComics(["1:5"]).far().make(),
            s.addComics(["2:0"]).make(),

            s.addComics(["2:1"]).far().make(),
            s.addComics(["2:2"]).make(),
            s.addComics(["2:2"]).inside().make(),
            s.addComics(["2:2"]).make(),
            s.addComics(["2:3"]).far().make(),

            s.addComics(["3:0"]).make(),
            s.addComics(["3:1"]).make(),
            s.addComics(["3:1"]).inside().make(),
            s.addComics(["3:1"]).make(),
            s.addComics(["3:2"]).far().make(),

            s.addComics(["4:0"]).make(),
            s.addComics(["4:0"]).inside().make(),
            s.addComics(["4:0"]).make(),
            s.addComics(["4:1"]).make(),
          ]);
          get().shoot(23);
          setTimeout(next, 2500);
        },
        (next) => {
          get().shoot(24);
          get().setContinue(() => {
            getLedspClient().sendGameProgressEvent({
              eventType: "game-stage-entered",
              gameId: get().initProps.gameId,
              playerId: get().initProps.playerId,
              teamId: get().initProps.settings.configuration.teamId,
              step: "Final results",
              stage: "Feedback",
            });

            get().shoot(get().nextShootIndex);
            get().playSoundtrack("intro");

            get().setContinue(() => {
              if (get().isGameEnded) {
                getLedspClient()
                  .sendGameProgressEvent({
                    eventType: "game-ended",
                    gameId: get().initProps.gameId,
                    playerId: get().initProps.playerId,
                    teamId: get().initProps.settings.configuration.teamId,
                    cleared: !get().isTimerExpired,
                  })
                  .then(
                    () =>
                      (window.location.href =
                        get().initProps.settings.configuration.returnPath)
                  );
              }
            }, "End game");
          }, "Go to feedback");
          next();
        },
      ])
    );
  },
};

function finalFeedback(solution: string) {
  if (GOOD_SOLUTIONS.includes(solution)) return "good";
  if (BAD_SOLUTIONS.includes(solution)) return "bad";
  return "random";
}

const GOOD_SOLUTIONS = ["A1_B3", "A1_B4", "A2_B3", "A2_B4"];
const BAD_SOLUTIONS = ["A3_B1", "A3_B2", "A4_B2", "A4_B3"];
