import React from 'react';
import { useAsset } from 'use-asset';
import { useStore } from './store';

export const Preloader = () => {
  const url = useStore(s => s.assetUrl);

  const communicationLoader = useStore(s => s.communicationLoader);
  const videoLoader = useStore(s => s.videoLoader);
  const narrativeLoader = useStore(s => s.narrativeLoader);

  const video = (path: string) => url(`/assets/videos/_/${path}`);
  const music = (path: string) => url(`/assets/music/${path}`);
  const terminal = (path: string) => url(`/assets/messages/terminal/${path}`);
  const narrative = (path: string) => url(`/assets/messages/narrative/${path}`);

  /** videos */
  videoLoader.read(video('decisionsA1/video.webm'));
  useAsset(fetch, video('decisionsA1/in.avif'));
  useAsset(fetch, video('decisionsA1/out.avif'));
  videoLoader.read(video('decisionsA1_B1/video.webm'));
  useAsset(fetch, video('decisionsA1_B1/in.avif'));
  useAsset(fetch, video('decisionsA1_B1/out.avif'));
  videoLoader.read(video('decisionsA1_B2/video.webm'));
  useAsset(fetch, video('decisionsA1_B2/in.avif'));
  useAsset(fetch, video('decisionsA1_B2/out.avif'));
  videoLoader.read(video('decisionsA1_B3/video.webm'));
  useAsset(fetch, video('decisionsA1_B3/in.avif'));
  useAsset(fetch, video('decisionsA1_B3/out.avif'));
  videoLoader.read(video('decisionsA1_B4/video.webm'));
  useAsset(fetch, video('decisionsA1_B4/in.avif'));
  useAsset(fetch, video('decisionsA1_B4/out.avif'));
  videoLoader.read(video('decisionsA2/video.webm'));
  useAsset(fetch, video('decisionsA2/in.avif'));
  useAsset(fetch, video('decisionsA2/out.avif'));
  videoLoader.read(video('decisionsA2_B1/video.webm'));
  useAsset(fetch, video('decisionsA2_B1/in.avif'));
  useAsset(fetch, video('decisionsA2_B1/out.avif'));
  videoLoader.read(video('decisionsA2_B2/video.webm'));
  useAsset(fetch, video('decisionsA2_B2/in.avif'));
  useAsset(fetch, video('decisionsA2_B2/out.avif'));
  videoLoader.read(video('decisionsA2_B3/video.webm'));
  useAsset(fetch, video('decisionsA2_B3/in.avif'));
  useAsset(fetch, video('decisionsA2_B3/out.avif'));
  videoLoader.read(video('decisionsA2_B4/video.webm'));
  useAsset(fetch, video('decisionsA2_B4/in.avif'));
  useAsset(fetch, video('decisionsA2_B4/out.avif'));
  videoLoader.read(video('decisionsA3/video.webm'));
  useAsset(fetch, video('decisionsA3/in.avif'));
  useAsset(fetch, video('decisionsA3/out.avif'));
  videoLoader.read(video('decisionsA3_B1/video.webm'));
  useAsset(fetch, video('decisionsA3_B1/in.avif'));
  useAsset(fetch, video('decisionsA3_B1/out.avif'));
  videoLoader.read(video('decisionsA3_B2/video.webm'));
  useAsset(fetch, video('decisionsA3_B2/in.avif'));
  useAsset(fetch, video('decisionsA3_B2/out.avif'));
  videoLoader.read(video('decisionsA3_B3/video.webm'));
  useAsset(fetch, video('decisionsA3_B3/in.avif'));
  useAsset(fetch, video('decisionsA3_B3/out.avif'));
  videoLoader.read(video('decisionsA3_B4/video.webm'));
  useAsset(fetch, video('decisionsA3_B4/in.avif'));
  useAsset(fetch, video('decisionsA3_B4/out.avif'));
  videoLoader.read(video('decisionsA4/video.webm'));
  useAsset(fetch, video('decisionsA4/in.avif'));
  useAsset(fetch, video('decisionsA4/out.avif'));
  videoLoader.read(video('decisionsA4_B1/video.webm'));
  useAsset(fetch, video('decisionsA4_B1/in.avif'));
  useAsset(fetch, video('decisionsA4_B1/out.avif'));
  videoLoader.read(video('decisionsA4_B2/video.webm'));
  useAsset(fetch, video('decisionsA4_B2/in.avif'));
  useAsset(fetch, video('decisionsA4_B2/out.avif'));
  videoLoader.read(video('decisionsA4_B3/video.webm'));
  useAsset(fetch, video('decisionsA4_B3/in.avif'));
  useAsset(fetch, video('decisionsA4_B3/out.avif'));
  videoLoader.read(video('decisionsA4_B4/video.webm'));
  useAsset(fetch, video('decisionsA4_B4/in.avif'));
  useAsset(fetch, video('decisionsA4_B4/out.avif'));
  videoLoader.read(video('finale_bad/video.webm'));
  useAsset(fetch, video('finale_bad/in.avif'));
  useAsset(fetch, video('finale_bad/out.avif'));
  videoLoader.read(video('finale_game_over/video.webm'));
  useAsset(fetch, video('finale_game_over/in.avif'));
  useAsset(fetch, video('finale_game_over/out.avif'));
  videoLoader.read(video('finale_good/video.webm'));
  useAsset(fetch, video('finale_good/in.avif'));
  useAsset(fetch, video('finale_good/out.avif'));
  videoLoader.read(video('finale_random/video.webm'));
  useAsset(fetch, video('finale_random/in.avif'));
  useAsset(fetch, video('finale_random/out.avif'));
  videoLoader.read(video('intromission/video.webm'));
  useAsset(fetch, video('intromission/in.avif'));
  useAsset(fetch, video('intromission/out.avif'));
  videoLoader.read(video('sceltaA_meeting/video.webm'));
  useAsset(fetch, video('sceltaA_meeting/in.avif'));
  useAsset(fetch, video('sceltaA_meeting/out.avif'));

  /** terminals */

  communicationLoader.read('first-choice');
  communicationLoader.read('mission-claim');
  communicationLoader.read('mission');
  communicationLoader.read('terminal_A1');
  communicationLoader.read('terminal_A2');
  communicationLoader.read('terminal_A3');
  communicationLoader.read('terminal_A4');
  communicationLoader.read('terminal_finale_bad');
  communicationLoader.read('terminal_finale_game-over');
  communicationLoader.read('terminal_finale_good');
  communicationLoader.read('terminal_finale_random');
  communicationLoader.read('terminal_intromission_claim');
  communicationLoader.read('terminal_intromission');
  communicationLoader.read('terminal_sceltaA');

  /** narratives */

  narrativeLoader.read('decisions_A1_1');
  narrativeLoader.read('decisions_A1_2');
  narrativeLoader.read('decisions_A1_3');
  narrativeLoader.read('decisions_A1_4');
  narrativeLoader.read('decisions_B1_1');
  narrativeLoader.read('decisions_B1_2');
  narrativeLoader.read('decisions_B1_3');
  narrativeLoader.read('decisions_B1_4');
  narrativeLoader.read('decisions_B2_1');
  narrativeLoader.read('decisions_B2_2');
  narrativeLoader.read('decisions_B2_3');
  narrativeLoader.read('decisions_B2_4');
  narrativeLoader.read('decisions_B3_1');
  narrativeLoader.read('decisions_B3_2');
  narrativeLoader.read('decisions_B3_3');
  narrativeLoader.read('decisions_B3_4');
  narrativeLoader.read('decisions_B4_1');
  narrativeLoader.read('decisions_B4_2');
  narrativeLoader.read('decisions_B4_3');
  narrativeLoader.read('decisions_B4_4');
  narrativeLoader.read('effects-A1');
  narrativeLoader.read('effects-A2');
  narrativeLoader.read('effects-A3');
  narrativeLoader.read('effects-A4');
  narrativeLoader.read('end_game_page');
  narrativeLoader.read('finale_bad');
  narrativeLoader.read('finale_game-over');
  narrativeLoader.read('finale_good');
  narrativeLoader.read('finale_random');
  narrativeLoader.read('question_scelta');
  narrativeLoader.read('sceltaA_claim');
  narrativeLoader.read('sceltaA_main');
  narrativeLoader.read('start');
  narrativeLoader.read('team_claim');
  narrativeLoader.read('team_main');

  /** soundtracks */
  useAsset(fetch, music('decisionsA4_B4/in.avif'));

  useAsset(fetch, music('10.final_random_Long Distance Goodbye Landing.opus'));
  useAsset(fetch, music('11_ final_bad_game_over_Leaving.opus'));
  useAsset(fetch, music('1.start_page_ArmSound_Demands.opus'));
  useAsset(
    fetch,
    music('2.video_intro_terminal_intro_ArmSound_ Armageddon Suite.opus')
  );
  useAsset(fetch, music('3.meet_team_ArmaSound_Armadillo.opus'));
  useAsset(fetch, music('4.sceltaA_Death of MIR.opus'));
  useAsset(fetch, music('5.decisionsA_B_Death of MIR.opus'));
  useAsset(fetch, music('6.decisionsC,D_Long Distance Goodbye Landing.opus'));
  useAsset(fetch, music('7.sceltaB_ArmSound_Underwater Simulation.opus'));
  useAsset(fetch, music('8. decisionsBs_Evacuation.opus'));
  useAsset(fetch, music('9.final_good_ Launch.opus'));
  useAsset(fetch, music('harry-and-grace-make-peace.opus'));
  useAsset(fetch, music('main-title.opus'));
  useAsset(fetch, music('theme-song.opus'));

  return null;
};
