import Immutable from 'immutable';
import { PlayerInfo, PlayerIncidentReport } from './interfaces';

export class PlayerState extends Immutable.Record(
  {
    id: '--null--',
    displayName: '--null--',
    color: '--null--',
    solutionsByIncident: Immutable.Map<string, string>(),
  },
  'PlayerState'
) {
  static Null() {
    return new PlayerState();
  }

  static fromPlayerInfo(info: PlayerInfo) {
    return new PlayerState(info);
  }

  hasSolutionBeenRecorded(incident: string) {
    return this.solutionsByIncident.has(incident);
  }

  readSolution(incident: string) {
    return this.solutionsByIncident.get(incident);
  }

  recordSolution(incident: string, solution: string) {
    return this.update('solutionsByIncident', s => s.set(incident, solution));
  }

  toIncidentReport(incident: string): PlayerIncidentReport {
    return {
      id: this.id,
      displayName: this.displayName,
      color: this.color,
      recordedSolution: this.solutionsByIncident.get(incident, ''),
    };
  }
}
