import useMediaQuery from "../hooks/useMediaQuery.hook";
import useScaleFactor from "../hooks/useScaleFactor.hook";
import buildAssetPath from "../utils/buildAssetPath";

interface IProps {
  brandImg?: string;
}

const Header = (props: IProps) => {
  const scaleFactor = useScaleFactor();
  const XLScreenMediaQuery: boolean = useMediaQuery("(min-width: 1280px)");

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        backgroundColor: "Black",
        userSelect: "none",
        MozWindowDragging: "no-drag",
        padding: XLScreenMediaQuery ? "0 3.2rem" : "0 1rem",
        boxSizing: "border-box",
        height: `calc(100px * ${scaleFactor})`,
        zIndex: 20,
        boxShadow:
          "0 1px 2px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div
        style={{
          width: "33.3333%",
          display: "flex",
        }}
      >
        <img
          src={buildAssetPath("/images/logo_bocconi.png")}
          style={{
            height: "40%",
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "auto",
            userSelect: "none",
            MozWindowDragging: "no-drag",
          }}
          alt="Logo Bocconi"
        />
      </div>
      <div
        style={{
          width: "33.3333%",
          display: "flex",
        }}
      >
        {props.brandImg && (
          <img
            src={props.brandImg}
            style={{
              height: "60%",
              margin: "auto",
              userSelect: "none",
              MozWindowDragging: "no-drag",
            }}
            alt="Logo Fireballs"
          />
        )}
      </div>
      <div
        style={{
          width: "33.3333%",
          display: "flex",
        }}
      >
        <img
          src={buildAssetPath("/images/logo_built.png")}
          style={{
            height: "40%",
            marginTop: "auto",
            marginBottom: "auto",
            marginLeft: "auto",
            userSelect: "none",
            MozWindowDragging: "no-drag",
          }}
          alt="Logo BUILT"
        />
      </div>
    </div>
  );
};
export default Header;
