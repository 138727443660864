import { CSSProperties } from 'react';

export const styles = (opts: { debug: boolean }): IStyleMap => ({
  main: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'black',
    boxShadow: '#00000075 0px 0px 10px',
  },

  ui: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 10,
  },

  timer: {
    position: 'absolute',
    top: -16,
    left: '50%',
    marginLeft: -220,
    width: 440,
    height: 32,
    background: (opts.debug && 'red') || 'transparent',
    zIndex: 1,
  },

  sceneNavigation: {
    position: 'absolute',
    top: -32,
    right: 100,
    width: 180,
    height: 64,
    background: (opts.debug && 'red') || 'transparent',
    zIndex: 1,
  },

  zoom: {
    position: 'absolute',
    top: -25,
    right: -25,
    width: 50,
    height: 50,
    background: (opts.debug && 'red') || 'transparent',
    zIndex: 1,
  },

  mainNavigation: {
    position: 'absolute',
    bottom: -32,
    left: '50%',
    marginLeft: -110,
    width: 220,
    height: 64,
    background: (opts.debug && 'red') || 'transparent',
    zIndex: 1,
  },

  players: {
    position: 'absolute',
    bottom: -32,
    left: -32,
    width: 300,
    height: 420,
    background: (opts.debug && 'red') || 'transparent',
    zIndex: 1,
  },

  canvas: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
});

interface IStyleMap {
  [id: string]: CSSProperties;
}

interface IStyleMapFactory<A extends any[], T extends IStyleMap> {
  (...args: A): T;
}
