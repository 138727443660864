import { GroupProps } from "@react-three/fiber";
import { Text } from "@react-three/drei";
import React from "react";
import useSound from "use-sound";
import { motion } from "framer-motion-3d";
import { useStore } from "../../../store";

export const StartButton: React.FC<GroupProps> = (props) => {
  const url = useStore((s) => s.assetUrl);
  const isGameStarted = useStore((s) => s.isGameStarted);
  const initProps = useStore((s) => s.initProps);
  const startGame = useStore((s) => s.startGame);
  const [playClick] = useSound(url("/assets/sounds/_/start-hover.opus"), {
    volume: 0.5,
  });

  return (
    <group
      {...props}
      onClick={(e) => {
        if (!isGameStarted) {
          playClick();
          startGame();
        }
      }}
    >
      <Text
        color="black"
        maxWidth={0.5}
        fontSize={0.03}
        font={url("/assets/fonts/Quantico-Bold.woff")}
        anchorX={"center"}
        position={[0, 0, 0.01]}
      >
        START
      </Text>
      <motion.mesh
        castShadow
        receiveShadow
        whileHover={!isGameStarted ? { scale: 1.1 } : {}}
        whileTap={!isGameStarted ? { scale: 0.8 } : {}}
      >
        <boxGeometry args={[0.3, 0.1, 0]} />
        <motion.meshPhongMaterial color={!isGameStarted ? "yellow" : "#ddd"} />
      </motion.mesh>
    </group>
  );
};
