/* eslint-disable default-case */
/* eslint-disable indent */
import { StoreSlice } from '../utils/store-slice';
import {
  FocusState,
  ICameraPositionFn,
  IPlayerCameraStore,
} from '../interfaces/player-camera-store';
import { Vector3 } from 'three';
import { Shoot } from '../../scene-control/shoot';
import { GetState, SetState } from 'zustand';
import Immutable from 'immutable';

export const createPlayerCameraStore: StoreSlice<IPlayerCameraStore> = (
  set,
  get
) => ({
  isShutterOpen: true,
  isSuperFocusOn: 'normal',
  baseCameraPosition: () => new Vector3(0, 0, 0),
  cameraPosition: (...args) => get().baseCameraPosition(...args),

  openShutter: () => set({ isShutterOpen: true }),
  closeShutter: () => set({ isShutterOpen: false }),

  focusCameraOn(shoot: Shoot) {
    set({
      baseCameraPosition: focusOn(shoot)(set, get),
      cameraPosition: removeSuperFocus(set, get),
    });
  },

  setSuperFocus: state =>
    set(s => ({
      isSuperFocusOn: state,
      cameraPosition: FOCUSES.get((!s.isShutterOpen && state) || 'normal')(
        set,
        get
      ),
    })),

  toggleSuperFocus: () => {
    switch (get().isSuperFocusOn) {
      case 'close':
        return get().setSuperFocus('normal');
      case 'normal':
        return get().setSuperFocus('eagle');
      case 'eagle':
        return get().setSuperFocus('close');
    }
  },
});

export const serializePlayerCameraStore = (s: any) => ({
  isSuperFocusOn: s.isSuperFocusOn,
});

export const deserializePlayerCameraStore = (raw: any) => ({
  isShutterOpen: true,
  isSuperFocusOn: raw.isSuperFocusOn,
});

interface IApplyFocusFn {
  (
    set: SetState<IPlayerCameraStore>,
    get: GetState<IPlayerCameraStore>
  ): ICameraPositionFn;
}

const focusOn =
  (shoot: Shoot) =>
  (
    set: SetState<IPlayerCameraStore>,
    get: GetState<IPlayerCameraStore>
  ): ICameraPositionFn =>
  (scene, camera) =>
    shoot.toCameraPosition(
      scene,
      Shoot.cameraPositionFromBox3(camera.fov || 35)
    );

const addSuperFocus =
  (
    _set: SetState<IPlayerCameraStore>,
    get: GetState<IPlayerCameraStore>
  ): ICameraPositionFn =>
  (...args) =>
    get()
      .baseCameraPosition(...args)
      .clone()
      .add(new Vector3(0, 0, -0.5));

const removeSuperFocus =
  (
    _set: SetState<IPlayerCameraStore>,
    get: GetState<IPlayerCameraStore>
  ): ICameraPositionFn =>
  (...args) =>
    get().baseCameraPosition(...args);

const eagleSuperFocus =
  (
    _set: SetState<IPlayerCameraStore>,
    get: GetState<IPlayerCameraStore>
  ): ICameraPositionFn =>
  (...args) =>
    get()
      .baseCameraPosition(...args)
      .clone()
      .add(new Vector3(0, 0, 4));

const FOCUSES = Immutable.Map<FocusState, IApplyFocusFn>()
  .set('close', addSuperFocus)
  .set('normal', removeSuperFocus)
  .set('eagle', eagleSuperFocus);
