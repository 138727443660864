import { Sequence } from '../scene-control/sequence';
import { SceneInfo } from '../store/factories/create-script-store';
import { IAssetsLoaderStore } from '../store/interfaces/assets-loader-store';
import { IBasicGameStore } from '../store/interfaces/basic-game-store';
import { ICommunicationsStore } from '../store/interfaces/communications-store';
import { INarrativeStore } from '../store/interfaces/narrative-store';
import { IPlayerCameraStore } from '../store/interfaces/player-camera-store';
import { IScreenPlayStore } from '../store/interfaces/screen-play-store';
import { IScriptStore } from '../store/interfaces/script-store';
import { ISequencePlayerStore } from '../store/interfaces/sequence-player-store';
import { ISoundtrackStore } from '../store/interfaces/soundtrack-store';
import { IVideoMixerStore } from '../store/interfaces/video-mixer-store';
import { shootBuilder as s } from './utils';

export const TeamScene: SceneInfo<
  IScreenPlayStore &
    ISoundtrackStore &
    IVideoMixerStore &
    ICommunicationsStore &
    IPlayerCameraStore &
    ISequencePlayerStore &
    INarrativeStore &
    IScriptStore &
    IBasicGameStore &
    IAssetsLoaderStore
> = {
  play(set, get) {
    set({ nextSceneIndex: 'first-choice' });
    get().turnUIOn();

    get().setContinue(() => {
      if (get().isLastShoot) get().playScene(get().nextSceneIndex);
      else get().shoot(get().nextShootIndex);
    });

    get().assignNarrativeChannel('2', {
      message: 'sceltaA_main',
      picture: 'team_intro',
    });

    get().assignNarrativeChannel('3', {
      message: 'sceltaA_claim',
      picture: 'team_intro',
    });

    get().playSequence(
      Sequence.fromStages([
        next => {
          get().playSoundtrack('team');

          get().replaceShootList([
            s.addComics(['0:0']).far().make(),
            s.addComics(['0:1']).make(),
            s.addComics(['0:1']).inside().make(),
            s.addComics(['0:1']).make(),
            s.addComics(['1:0']).make(),

            s.addComics(['1:1']).make(),
          ]);
          get().shoot(5);

          next();
        },
      ])
    );
  },
};
