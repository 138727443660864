import React from 'react';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const BarsContainer = ({ children }: IProps) => (
  <span
    id="bars-container"
    style={{
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      height: '4px',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    }}
  >
    {children}
  </span>
);

export default BarsContainer;
