import { IAudioPlayer } from './audio-player.interface';

export class SoundtrackMixer {
  static setup() {
    return new SoundtrackMixer();
  }

  private currentSoundtrack: IAudioPlayer = null;
  private currentSoundId: number = 0;

  play(soundtrack: IAudioPlayer, fadeTime: number = 1000) {
    this.currentSoundtrack &&
      this.currentSoundtrack.fade(0.5, 0, fadeTime, this.currentSoundId);

    this.currentSoundId = soundtrack.play();
    this.currentSoundtrack = soundtrack;

    this.currentSoundtrack.fade(0, 0.5, fadeTime, this.currentSoundId);
  }

  stop() {
    this.currentSoundtrack &&
      this.currentSoundtrack.fade(0.5, 0, 1000, this.currentSoundId);
  }
}
