import React from 'react';
import * as THREE from 'three';
import { FULL } from '../utils';

export const VideoProjection: React.FC<VideoProjectionProps> = props => (
  <mesh position={[0, 0, 0.001]}>
    <boxGeometry args={FULL.clone().multiplyScalar(0.93).toArray()} />
    <meshPhongMaterial transparent>
      <videoTexture
        encoding={THREE.sRGBEncoding}
        attach="map"
        args={[props.videoTexture]}
      />
      <videoTexture attach="emissiveMap" args={[props.videoTexture]} />
    </meshPhongMaterial>
  </mesh>
);

type VideoProjectionProps = {
  readonly videoTexture: HTMLVideoElement;
};
