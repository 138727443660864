import { CSSProperties } from 'react';

export const styles = (): IStyleMap => ({
  main: {
    position: 'fixed',
    width: 512,
    height: 256,
    left: 32,
    bottom: 32,
    background: '#ffffff75',
    fontFamily: 'monospace',
    zIndex: 100,
    padding: 16,
    overflowY: 'scroll',
  },
});

interface IStyleMap {
  [id: string]: CSSProperties;
}
