import React, { useMemo } from 'react';
import { useStore } from '../../store';
import { NextButton } from './next-button';
import { PlayersListController } from './players-list-controller';
import { HTMLMotionProps, motion } from 'framer-motion';

import { styles } from './styles';
import { Timer } from './timer';
import { ZoomButton } from './zoom-button';
import { ShootNavigation } from './shoot-navigation';

export const UI = () => {
  const debug = useStore(s => s.areVisualGuidesVisible);
  const s = useMemo(() => styles({ debug }), [debug]);

  return (
    <>
      <UIElement style={s.timer}>
        <Timer />
      </UIElement>
      <UIElement style={s.sceneNavigation}>
        <ShootNavigation />
      </UIElement>
      <UIElement style={s.mainNavigation}>
        <NextButton />
      </UIElement>
      <UIElement style={s.zoom}>{/* <ZoomButton /> */}</UIElement>
      <UIElement style={s.players}>
        <PlayersListController />
      </UIElement>
    </>
  );
};

const UIElement: React.FC<HTMLMotionProps<'div'>> = props => {
  const isUIOn = useStore(s => s.isUIOn);
  const isShutterOpen = useStore(s => s.isShutterOpen);

  const isUIVisible = isUIOn && !isShutterOpen;

  return (
    <motion.div
      {...props}
      variants={{
        off: {
          opacity: 0,
          pointerEvents: 'none',
        },
        on: {
          opacity: 1,
          pointerEvents: 'auto',
        },
      }}
      initial="off"
      animate={(isUIVisible && 'on') || 'off'}
      transition={{ delay: 1 }}
    />
  );
};
