import { StoreSlice } from '../utils/store-slice';
import { IVideoMixerStore } from '../interfaces/video-mixer-store';
import Immutable from 'immutable';
import { IAssetsLoaderStore } from '../interfaces/assets-loader-store';
import { ILightDimmerStore } from '../interfaces/light-dimmer-store';
import { VideoChannelStatus } from '../../model/video-channel-status';
import { IScreenPlayStore } from '../interfaces/screen-play-store';
import { IBasicGameStore } from '../interfaces/basic-game-store';

export const createVideoMixerStore: StoreSlice<
  IVideoMixerStore & { _callbacks: Immutable.Map<string, () => void> },
  IAssetsLoaderStore & ILightDimmerStore & IScreenPlayStore & IBasicGameStore
> = (set, get) => ({
  videoChannels: Immutable.Map<string, VideoChannelStatus>(),

  playVideoChannel: channel => {
    const channelStatus = get().videoChannels.get(channel);
    if (channelStatus) {
      const video = get().videoLoader.peek(channelStatus.videoPath);

      console.log(
        `»» `,
        `play video "${channelStatus.videoPath}" on channel «${channel}»`
      );

      if (!video) console.log(`»» video on channel «${channel}» is not ready`);

      if (video) {
        get().switchLightsOff();
        video.play().then(() => {
          console.log(`»» `, `video started on channel «${channel}»`);

          const goOn = get().continue;
          const goOnLabel = get().continueLabel;

          get().setContinue(() => {
            video.currentTime = video.duration;
          }, 'Skip');

          const wasNavigationVisible = get().isNavigationVisible;
          get().turnNavigationOff();

          function onVideoEnded(e: Event) {
            console.log('»» ', `video ended on channel «${channel}»`);
            get().switchLightsOn();
            set(s => ({
              videoChannels: s.videoChannels.update(channel, v =>
                v.playEnded()
              ),
            }));

            e.target.removeEventListener('ended', onVideoEnded);

            wasNavigationVisible && get().turnNavigationOn();
            get().setContinue(goOn, goOnLabel);

            const cb = get()._callbacks.get(channel);

            set(s => ({
              _callbacks: s._callbacks.remove(channel),
            }));

            if (cb) cb();
            else if (get().shootingDirection === 'forward')
              get().shoot(get().nextShootIndex);
            else get().shoot(get().prevShootIndex);
          }

          video.addEventListener('ended', onVideoEnded);

          set(s => ({
            videoChannels: s.videoChannels.update(channel, v =>
              v.playStarted()
            ),
          }));
        });
      }
    }
  },

  loadVideoChannel: (channel, source) => {
    console.log(
      '»» ',
      `loading video "${source.videoPath}" on channel «${channel}»`
    );

    get().videoLoader.preload(source.videoPath);

    set(s => ({
      videoChannels: s.videoChannels.set(
        channel,
        VideoChannelStatus.fromVideoSource({
          closingThumbPath: source.closingThumbPath,
          openingThumbPath: source.openingThumbPath,
          videoPath: source.videoPath,
        })
      ),
    }));
  },

  _callbacks: Immutable.Map<string, () => void>(),

  onVideoEnded: (channel, cb) => {
    const channelStatus = get().videoChannels.get(channel);
    if (!channelStatus) return;

    console.log(`»» `, `attached end callback to video channel «${channel}»`);

    function handler() {
      console.log('»» ', `end callback called on channel «${channel}»`);
      cb();
    }

    set(s => ({
      _callbacks: s._callbacks.set(channel, handler),
    }));
  },

  reloadAllVideoChannels: () => {
    get().videoChannels.forEach((channel, channelId) => {
      get().loadVideoChannel(channelId, channel);
    });
  },
});

export const serializeVideoMixerStore = (s: any) => ({
  videoChannels: s.videoChannels.map(VideoChannelStatus.encode).toJSON(),
});

export const deserializeVideoMixerStore = (raw: any) => ({
  videoChannels: Immutable.Map(raw.videoChannels).map(
    VideoChannelStatus.decode
  ),
  _callbacks: Immutable.Map<string, () => void>(),
});
