import { StoreSlice } from '../utils/store-slice';
import { GetState, SetState } from 'zustand';
import { ISequencePlayerStore } from '../interfaces/sequence-player-store';
import { SequencePlayer } from '../../scene-control/sequence-player';

export const createSequencePlayerStore: StoreSlice<ISequencePlayerStore> = (
  set,
  get
) => ({
  sequencePlayer: SequencePlayer.Init(),
  playSequence: sequence => {
    get().sequencePlayer.stopAll().play(sequence);
  },
});

export type SceneInfo<T extends object = any> = {
  play(set: SetState<T>, get: GetState<T>): void;
};

export const serializeSequencePlayerStore = (s: any) => ({});

export const deserializeSequencePlayerStore = (raw: any) => ({
  sequencePlayer: SequencePlayer.Init(),
});
