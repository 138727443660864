import Immutable from 'immutable';
import memoizee from 'memoizee';
import { Howl } from 'howler';
import { StoreSlice } from '../utils/store-slice';
import { ISoundStore } from '../interfaces/sound-store';
import { SoundtrackInfo } from '../interfaces/soundtrack-store';
import { IAssetsLoaderStore } from '../interfaces/assets-loader-store';

export const createSoundStore: StoreSlice<
  ISoundStore & { _sounds: Immutable.Map<string, number> },
  IAssetsLoaderStore
> = (set, get) => ({
  _sounds: Immutable.Map<string, number>(),

  playSound: name => {
    console.log(`»» play sound «${name}»`);

    const info = SOUNDS.get(name);
    const player = info.prepare(get().assetUrl);
    const soundId = player.play();

    set({ _sounds: get()._sounds.set(name, soundId) });
  },

  stopAudio: name => {
    const info = SOUNDS.get(name);
    const player = info.prepare(get().assetUrl);
    const soundId = get()._sounds.get(name);

    console.log(`»» stop sound «${name}» with sound-id «${soundId}»`);

    if (soundId) player.fade(1, 0, 1000, soundId);
  },
});

export const deserializeSoundStore = (raw: any) => ({
  _sounds: Immutable.Map<string, number>(),
});

const loadSound = memoizee(
  (url: string, loop: boolean = false, volume: number = 0.5) =>
    new Howl({
      src: [url],
      volume,
      loop,
    })
);

const SOUNDS = Immutable.Map<string, SoundtrackInfo>()
  .set('camera-moves-1', {
    prepare: url => loadSound(url('/assets/sounds/_/camera-moves-1.mp3')),
  })
  .set('camera-moves-2', {
    prepare: url => loadSound(url('/assets/sounds/_/camera-moves-2.mp3')),
  })
  .set('timer-expiring', {
    prepare: url => loadSound(url('/assets/sounds/_/timer-expiring.opus')),
  })
  .set('timer-last-seconds', {
    prepare: url =>
      loadSound(url('/assets/sounds/_/timer-last-seconds.opus'), true),
  })
  .set('communication-start', {
    prepare: url => loadSound(url('/assets/sounds/_/communication-start.opus')),
  })
  .set('communication-end', {
    prepare: url => loadSound(url('/assets/sounds/_/communication-end.opus')),
  })
  .set('communication-typing', {
    prepare: url =>
      loadSound(url('/assets/sounds/_/communication-typing.opus'), true),
  });
