import { useTexture } from "@react-three/drei";
import { Text } from "@react-three/drei";
import React from "react";
import { useStore } from "../../../store";
import { SMALL } from "../utils";

export const SmallNarrative: React.FC<SmallNarrativeProps> = (props) => {
  const url = useStore((s) => s.assetUrl);
  const loader = useStore((s) => s.narrativeLoader);
  const narrative = useStore((s) => s.narrativeChannels).get(props.name);

  const text = (narrative && loader.read(narrative.message)) || "---";

  return (
    <>
      <Text
        color="black"
        maxWidth={SMALL.x * 0.75}
        lineHeight={1.3}
        letterSpacing={0.05}
        fontSize={0.04}
        font={url("/assets/fonts/Caveat-VariableFont_wght.woff")}
        anchorX={"left"}
        position={[-0.3, 0, 0.01]}
      >
        {text.toUpperCase()}
      </Text>
      <SmallNarrativeFrame />
    </>
  );
};

type SmallNarrativeProps = {
  name: string;
};

export const SmallNarrativeFrame: React.FC = (props) => {
  const url = useStore((s) => s.assetUrl);
  const texture = useTexture(url("/assets/textures/ui/c_narrative_small.svg"));

  return (
    <mesh castShadow receiveShadow>
      <boxGeometry args={SMALL.toArray()} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};
