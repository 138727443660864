import { FC } from "react";
import { motion as m } from "framer-motion-3d";
import { useThree } from "@react-three/fiber";
import { Vector3 } from "three";

export const Shutter: FC<ShutterProps> = (props) => {
  const camera = useThree(({ camera }) => camera);
  const opacity = (props.isOn && 1) || 0;

  return (
    <mesh
      position={camera.position.clone().add(new Vector3(0, 0, -0.2))}
      onClick={(e) => {
        props.isOn && e.stopPropagation();
        props.onClick && props.onClick();
      }}
    >
      <mesh>
        <boxGeometry args={[1000, 1000, 0]} />
        <m.meshPhongMaterial
          color="#000000"
          opacity={1}
          animate={{ opacity }}
          transition={{ duration: 2, delay: 1 }}
          onAnimationComplete={(definition) => {
            const def: any = definition;
            if (def.opacity === 0) props.onClosed && props.onClosed();
          }}
          transparent
        />
      </mesh>
      {props.children}
    </mesh>
  );
};

export type ShutterProps = {
  readonly isOn: boolean;
  children: any;
  onClick?(): void;
  onClosed?(): void;
};
