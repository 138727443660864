import { StoreSlice } from '../utils/store-slice';
import {
  CommunicationInfo,
  ICommunicationStatus,
  ICommunicationsStore,
} from '../interfaces/communications-store';
import Immutable from 'immutable';
import { CommunicationStatus } from '../../model/communication-status';
import { ISoundStore } from '../interfaces/sound-store';

export const createCommunicationsStore: StoreSlice<
  ICommunicationsStore,
  ISoundStore
> = (set, get) => ({
  terminals: Immutable.Map<string, ICommunicationStatus>(),
  endCommunication: terminalId => {
    const terminal = get().terminals.get(terminalId);

    if (!terminal || terminal.isEnded) return;

    get().stopAudio('communication-typing');
    get().playSound('communication-end');

    set(s => ({
      terminals: s.terminals.update(terminalId, v => v.end()),
    }));
  },
  startCommunication: (terminalId: string, info: CommunicationInfo) => {
    if (
      get().terminals.has(terminalId) &&
      get().terminals.get(terminalId).isStarted
    )
      return;

    get().playSound('communication-start');
    get().playSound('communication-typing');

    set(s => ({
      terminals: s.terminals.set(
        terminalId,
        CommunicationStatus.fromCommunicationInfo(info)
          .linkTerminal(terminalId)
          .start()
      ),
    }));
  },
});

export const serializeCommunicationsStore = (s: any) => ({
  terminals: s.terminals.map(CommunicationStatus.encode).toJSON(),
});

export const deserializeCommunicationsStore = (raw: any) => ({
  terminals: Immutable.Map(raw.terminals).map(CommunicationStatus.decode),
});
