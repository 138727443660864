import { useEffect, useState } from 'react';

function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean =>
    typeof window === 'undefined' ? false : window.matchMedia(query).matches;

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  const handleChange = () => setMatches(getMatches(query));

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    handleChange();

    // Sasfari
    if (matchMedia.addListener) matchMedia.addListener(handleChange);
    // Chrome, Firefox
    else matchMedia.addEventListener('change', handleChange);

    return () =>
      matchMedia.removeListener
        ? matchMedia.removeListener(handleChange)
        : matchMedia.removeEventListener('change', handleChange);
  }, [query]);

  return matches;
}

export default useMediaQuery;
