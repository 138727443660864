import { useTexture } from "@react-three/drei";
import { useStore } from "../../../store";
import React, { FC } from "react";
import { FULL } from "../utils";

export const FullTerminalFrame: FC = () => {
  const url = useStore((s) => s.assetUrl);
  const texture = useTexture(url("/assets/textures/ui/c_system_full.svg"));

  return (
    <mesh castShadow receiveShadow>
      <boxGeometry args={FULL.toArray()} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};
