"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameFlowValidationError = void 0;
const game_progress_1 = require("./game-progress");
class GameFlowValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = "GameFlowValidationError";
    }
}
exports.GameFlowValidationError = GameFlowValidationError;
function ValidateGameFlow(gameConcept, events, expectedEvents, eventToEmit, errorReporter) {
    try {
        if (eventToEmit.eventType === game_progress_1.GAME_STARTED &&
            events.some(({ eventType }) => eventType === game_progress_1.GAME_STARTED))
            throw new GameFlowValidationError(`Duplicated GAME_STARTED event`);
        if (eventToEmit.eventType !== game_progress_1.GAME_STARTED &&
            !events.some(({ eventType }) => eventType === game_progress_1.GAME_STARTED))
            throw new GameFlowValidationError(`GAME_STARTED event is missing`);
        if (eventToEmit.eventType === game_progress_1.GAME_STAGE_ENTERED) {
            const expectedEventIndex = events.filter(({ eventType }) => eventType === game_progress_1.GAME_STAGE_ENTERED).length;
            const expectedEvent = expectedEvents[expectedEventIndex];
            if (!expectedEvent)
                throw new GameFlowValidationError(`Events out of order: no more events expected, got ${eventToEmit.step}/${eventToEmit.stage}`);
            if (expectedEvent.step !== eventToEmit.step ||
                expectedEvent.stage !== eventToEmit.stage)
                throw new GameFlowValidationError(`Events out of order: expected ${expectedEvent.step}/${expectedEvent.stage}, got ${eventToEmit.step}/${eventToEmit.stage}`);
        }
        if (eventToEmit.eventType === game_progress_1.GAME_ENDED &&
            events.some(({ eventType }) => eventType === game_progress_1.GAME_ENDED))
            throw new GameFlowValidationError(`Duplicated GAME_ENDED event`);
        if (eventToEmit.eventType === game_progress_1.GAME_ENDED && !eventToEmit.cleared) {
            for (const step of gameConcept.gameFlow) {
                const phase = gameConcept.phases.find(({ name }) => name === step.phase);
                for (const stage of phase.stages)
                    if (!events.some((e) => e.eventType === game_progress_1.GAME_STAGE_ENTERED &&
                        e.step === step.id &&
                        e.stage === stage))
                        throw new GameFlowValidationError(`Missing event: GAME_STAGE_ENTERED with step: ${step.id} and stage: ${stage}!`);
            }
        }
    }
    catch (error) {
        errorReporter((error === null || error === void 0 ? void 0 : error.message) || error);
    }
}
exports.default = ValidateGameFlow;
