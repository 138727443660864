import { Sequence } from '../scene-control/sequence';
import { SceneInfo } from '../store/factories/create-script-store';
import { INarrativeStore } from '../store/interfaces/narrative-store';
import { IScreenPlayStore } from '../store/interfaces/screen-play-store';
import { IScriptStore } from '../store/interfaces/script-store';
import { ISequencePlayerStore } from '../store/interfaces/sequence-player-store';
import { ISoundtrackStore } from '../store/interfaces/soundtrack-store';
import { shootBuilder as s } from './utils';

export const Cover: SceneInfo<
  IScriptStore &
    IScreenPlayStore &
    ISoundtrackStore &
    ISequencePlayerStore &
    INarrativeStore
> = {
  play(set, get) {
    get().assignNarrativeChannel('1', {
      message: 'team_main',
      picture: 'team_intro',
    });

    get().playSequence(
      Sequence.fromStages([
        cb => {
          get().playSoundtrack('intro');
          get().replaceShootList([s.addComics(['0:0']).far().make()]);
          get().shoot(0);
          set({ nextSceneIndex: 'a-threat-to-m1' });
          cb();
        },
      ])
    );
  },
};
