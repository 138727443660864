import Immutable from 'immutable';
import { IOnStageEndedFn, ISequence, IStageFn } from './sequence.interface';

export class Sequence implements ISequence {
  constructor(
    private readonly stages: Immutable.List<IStageFn>,
    private comment: string = ''
  ) {}

  playStage(index: number, onStageEnded: IOnStageEndedFn) {
    const stage = this.stages.get(index);
    if (!stage) return onStageEnded(this, index, null);

    stage(() => onStageEnded(this, index, index + 1));
  }

  hashCode(): number {
    return 0;
  }

  equals(other: unknown): boolean {
    return other instanceof Sequence && other.stages.equals(this.stages);
  }

  toString() {
    return this.comment;
  }

  static Null() {
    return new NullSequence();
  }

  static fromStages(stages: Iterable<IStageFn>, comment?: string) {
    return new Sequence(Immutable.List(stages), comment);
  }
}

class NullSequence implements ISequence {
  playStage(index: number, onStageEnded: IOnStageEndedFn) {
    onStageEnded(this, index, null);
  }

  hashCode(): number {
    return 0;
  }

  equals(other: unknown): boolean {
    return other instanceof NullSequence;
  }
}
