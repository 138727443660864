import getLedspClient from "../../utils/get-ledsp-client";
import { Sequence } from "../scene-control/sequence";
import { SceneInfo } from "../store/factories/create-script-store";
import { IAssetsLoaderStore } from "../store/interfaces/assets-loader-store";
import { IBasicGameStore } from "../store/interfaces/basic-game-store";
import { ICommunicationsStore } from "../store/interfaces/communications-store";
import { INarrativeStore } from "../store/interfaces/narrative-store";
import { IPlayerCameraStore } from "../store/interfaces/player-camera-store";
import { IScreenPlayStore } from "../store/interfaces/screen-play-store";
import { IScriptStore } from "../store/interfaces/script-store";
import { ISequencePlayerStore } from "../store/interfaces/sequence-player-store";
import { ISoundtrackStore } from "../store/interfaces/soundtrack-store";
import { IVideoMixerStore } from "../store/interfaces/video-mixer-store";
import { shootBuilder as s } from "./utils";

export const FirstChoice: SceneInfo<
  IScreenPlayStore &
    ISoundtrackStore &
    IVideoMixerStore &
    ICommunicationsStore &
    IPlayerCameraStore &
    ISequencePlayerStore &
    INarrativeStore &
    IScriptStore &
    IBasicGameStore &
    IAssetsLoaderStore
> = {
  play(set, get) {
    getLedspClient().sendGameProgressEvent({
      eventType: "game-stage-entered",
      gameId: get().initProps.gameId,
      playerId: get().initProps.playerId,
      teamId: get().initProps.settings.configuration.teamId,
      step: "Incident A",
      stage: "Context discovery",
    });

    set({ nextSceneIndex: "first-choice-decision" });
    get().turnUIOn();

    get().setContinue(() => {
      get().endCommunication("2");

      if (get().nextShootIndex === 7) {
        get().turnUIOff();
        setTimeout(() => {
          get().shoot(get().nextShootIndex);
          get().turnUIOn();
        }, 2500);
      }

      if (get().isLastShoot) get().playScene(get().nextSceneIndex);
      else get().shoot(get().nextShootIndex);
    });

    get().loadVideoChannel("2", {
      videoPath: get().assetUrl("/assets/videos/_/sceltaA_meeting/video.webm"),
      openingThumbPath: get().assetUrl(
        "/assets/videos/_/sceltaA_meeting/in.avif"
      ),
      closingThumbPath: get().assetUrl(
        "/assets/videos/_/sceltaA_meeting/out.avif"
      ),
    });

    get().playSequence(
      Sequence.fromStages([
        (cb) => {
          get().playSoundtrack("first-choice");

          get().replaceShootList([
            s.addComics(["0:0"]).far().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["0:1"]).inside().make(),
            s.addComics(["0:1"]).make(),
            s.addComics(["1:0"]).make(),

            s.addComics(["1:1"]).make(),
            s.addComics(["1:2"]).far().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:3"]).inside().make(),
            s.addComics(["1:3"]).make(),
            s.addComics(["1:4"]).make(),
            s.addComics(["1:5"]).far().make(),
          ]);
          get().shoot(6);

          setTimeout(cb, 2500);
        },
        (cb) => {
          get().startCommunication("2", {
            message: "terminal_sceltaA",
          });
          cb();
        },
      ])
    );
  },
};
