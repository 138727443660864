import React from 'react';
import useSound from 'use-sound';
import { useStore } from '../../store';

const AvatarIndexes = {
  yellow: 1,
  blue: 2,
  green: 3,
  red: 4,
  pink: 5,
};

interface IProps {
  bordered: boolean;
  displayName: string;
  recordedSolution: string;
  color: string;
  handleClick: () => void;
}

const PlayerRow = ({
  bordered,
  displayName,
  recordedSolution,
  color,
  handleClick,
}: IProps) => {
  const url: (path: string) => string = useStore(s => s.assetUrl);

  const [playHover, { stop: stopHover }] = useSound(
    url(`/assets/sounds/_/player-${color}-hover.opus`)
  );
  const [playClick] = useSound(
    url('/assets/sounds/_/player-record-choice.opus')
  );

  return (
    <div
      onMouseEnter={() => playHover()}
      onMouseLeave={() => stopHover()}
      onClick={() => {
        playClick();
        handleClick();
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        marginBottom: '8px',
      }}
    >
      <img
        src={url(`/assets/textures/ui/ui_astro_${AvatarIndexes[color]}.svg`)}
        alt={displayName}
        title={color}
        style={{
          outline: bordered && '4px solid gold',
          outlineOffset: bordered && '-4px',
          marginRight: '8px',
          borderRadius: '50%',
          height: '52px',
          width: '52px',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          textTransform: 'uppercase',
          lineHeight: '1.1',
          fontSize: '0.8rem',
          fontWeight: 600,
        }}
      >
        <p style={{ fontStyle: 'italic' }}>{displayName}</p>
        <p style={{ fontWeight: 800 }}>
          {recordedSolutionLabel(recordedSolution)}
        </p>
      </div>
    </div>
  );
};

export default PlayerRow;

function recordedSolutionLabel(solution: string) {
  if (solution === '') return 'no option chosen yet';
  return `option «${solution}» chosen`;
}
