"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
class HttpClient {
    constructor(endpoint) {
        this.endpoint = endpoint;
    }
    request(method, path, body, customConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            return fetch(this.endpoint.concat("/", path), Object.assign(Object.assign({ method }, customConfig), { headers: Object.assign({}, customConfig === null || customConfig === void 0 ? void 0 : customConfig.headers), body: body ? JSON.stringify(body) : undefined })).then((response) => __awaiter(this, void 0, void 0, function* () {
                if (response.status === 403)
                    return Promise.reject(new Error(yield response.text()));
                else if (response.status === 401) {
                    window.location.assign(window.location.toString());
                    return;
                }
                else if (response.ok) {
                    const responseText = yield response.text();
                    try {
                        const responseJson = JSON.parse(responseText);
                        return responseJson;
                    }
                    catch (jsonParsingError) {
                        return responseText;
                    }
                }
                else
                    return Promise.reject(new Error(yield response.text()));
            }));
        });
    }
    get(path, config = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.request("GET", path, undefined, config);
        });
    }
    post(path, body, config = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.request("POST", path, body, Object.assign(Object.assign({}, config), { headers: Object.assign(Object.assign({}, config === null || config === void 0 ? void 0 : config.headers), { "Content-Type": "application/json" }) }));
        });
    }
    delete(path, body = {}, config = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.request("DELETE", path, body, Object.assign(Object.assign({}, config), { headers: Object.assign(Object.assign({}, config === null || config === void 0 ? void 0 : config.headers), { "Content-Type": "application/json" }) }));
        });
    }
}
exports.default = HttpClient;
