import { useStore } from "../../../store";
import React from "react";
import { SmallMessageTerminalText } from "./message-terminal-text";
import { SmallTerminalFrame } from "./small-terminal-frame";
import { TerminalTextLoader } from "./terminal-text-loader";
import { Typewriter } from "./typewriter";
import { CommunicationStatus } from "../../../model/communication-status";

export const SmallTerminal: React.FC<{ name: string }> = (props) => {
  const status = useStore((s) => s.terminals).get(
    props.name,
    CommunicationStatus.Empty()
  );

  const endCommunication = useStore((s) => s.endCommunication);

  return (
    <>
      <TerminalTextLoader
        message={status.message}
        isStarted={status.isStarted}
        isEnded={status.isEnded}
        typewriter={(props) => (
          <Typewriter
            {...props}
            onTypingEnded={() => {
              endCommunication(status.terminalId);
            }}
          />
        )}
        text={SmallMessageTerminalText}
      />
      <SmallTerminalFrame />
    </>
  );
};
