import { StoreSlice } from '../utils/store-slice';
import Immutable from 'immutable';
import {
  INarrativeStatus,
  INarrativeStore,
  NarrativeInfo,
} from '../interfaces/narrative-store';

export const createNarrativeStore: StoreSlice<INarrativeStore> = (
  set,
  get
) => ({
  narrativeChannels: Immutable.Map<string, INarrativeStatus>(),
  assignNarrativeChannel: (channelId: string, info: NarrativeInfo) => {
    set(s => ({
      narrativeChannels: s.narrativeChannels.set(channelId, info),
    }));
  },
});

export const serializeNarrativeStore = (s: any) => ({
  narrativeChannels: s.narrativeChannels.toJSON(),
});

export const deserializeNarrativeStore = (raw: any) => ({
  narrativeChannels: Immutable.Map(raw.narrativeChannels),
});
