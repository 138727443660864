{
  "id": "fireballs-1.0.0",
  "name": "Fireballs v1.0.0",
  "availablePlayOptions": [
    {
      "name": "timeMode",
      "description": "",
      "format": "text",
      "required": true,
      "acceptedValues": ["on", "off"]
    },
    {
      "name": "timerDurationInSeconds",
      "description": "",
      "format": "number",
      "required": true
    },
    {
      "name": "rounds",
      "description": "",
      "format": "number",
      "required": true
    }
  ],
  "defaultPlayOptionsSet": {
    "timeMode": "off",
    "timerDurationInSeconds": "0",
    "rounds": 1
  },
  "playersPartyFormationInfo": {
    "sizeCriterion": {
      "kind": "size",
      "size": "5",
      "subKind": "number-of-members"
    },
    "minSize": "1",
    "maxSize": "5"
  },
  "teamFormationInfo": {
    "availableTeamRoles": ["Role1"],
    "teamSizingCriteria": {
      "kind": "size",
      "size": "5",
      "subKind": "number-of-members"
    },
    "uniqRolePerTeam": false
  },
  "phases": [
    {
      "name": "Introduction",
      "expectedDurationInSeconds": {
        "min": 0,
        "max": 1000
      },
      "stages": ["The mission"]
    },
    {
      "name": "Incident",
      "expectedDurationInSeconds": {
        "min": 0,
        "max": 1000
      },
      "stages": ["Context discovery", "Player decisions", "Team decision"]
    },
    {
      "name": "Final results",
      "expectedDurationInSeconds": {
        "min": 0,
        "max": 1000
      },
      "stages": ["Result", "Feedback"]
    }
  ],
  "gameFlow": [
    {
      "id": "Introduction",
      "phase": "Introduction",
      "description": "The goal of this phase is to introduce the players to the game."
    },
    {
      "id": "Incident A",
      "phase": "Incident",
      "description": "The goal of this phase is to find out a way to recover the project after the incident A."
    },
    {
      "id": "Incident B",
      "phase": "Incident",
      "description": "The goal of this phase is to find out a way to recover the project after the incident B."
    },
    {
      "id": "Final results",
      "phase": "Final results",
      "description": "The goal of this phase is to show the players results according to their decisions."
    }
  ]
}
