import { useTexture } from '@react-three/drei';
import { MeshProps } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';
import { useStore } from '../../store';

export const Paper: React.FC<MeshProps> = props => {
  const url = useStore(s => s.textureUrl);
  const texture = useTexture(url('/assets/textures/background.png'));
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(100, 100);

  return (
    <mesh {...props}>
      <boxGeometry args={[90, 160, 0]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
};
