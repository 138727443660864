import { motion } from 'framer-motion';
import React from 'react';
import PlayerRow from './player-row';

interface IProps {
  isVisible: boolean;
  displayedSolution: string;
  applicableSolutions: string[];
  handlePlayerClick: (playerId: string) => void;
  players: {
    id: string;
    displayName: string;
    color: string;
    recordedSolution: string;
  }[];
}

const BoardFramePlayersList = ({
  isVisible,
  displayedSolution,
  applicableSolutions,
  handlePlayerClick,
  players,
}: IProps) => (
  <motion.div
    style={{
      padding: '16px 20px',
      userSelect: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      backgroundColor: '#1f1d28',
      border: '2px solid white',
      width: '100%',
      height: '100%',
      pointerEvents: 'auto',
    }}
    animate={
      isVisible
        ? { opacity: 1, pointerEvents: 'auto' }
        : { opacity: 0, pointerEvents: 'none' }
    }
  >
    <div style={{ fontSize: 12, marginBottom: '1em' }}>
      Collect the preference of each player by clicking on the corresponding
      helmet. Then you will be able to choose an option as a team.
    </div>
    {players?.map(player => (
      <PlayerRow
        key={player.id}
        displayName={player.displayName}
        recordedSolution={player.recordedSolution}
        handleClick={() => handlePlayerClick(player.id)}
        bordered={applicableSolutions.includes(player.recordedSolution)}
        color={player.color}
      />
    ))}
  </motion.div>
);

export default BoardFramePlayersList;
