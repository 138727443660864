import React from 'react';

const MiddleTick = () => (
  <span
    style={{
      height: '4px',
      width: '2px',
      left: '50%',
      backgroundColor: 'White',
      position: 'absolute',
      zIndex: 10,
      top: '-8px',
    }}
  />
);

export default MiddleTick;
