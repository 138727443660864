import React, { Suspense, useMemo } from "react";
import { useTexture, Text } from "@react-three/drei";
import { MeshProps } from "@react-three/fiber";
import { SQUARE } from "../utils";
import { ApplySolutionButton } from "./apply-solution-button";
import { DoubleSide } from "three";
import { useStore } from "../../../store";

export const Incident: React.FC<IncidentProps> = (props) => {
  const gameState = useStore((s) => s.gameState);

  const incident = gameState.incidentByOrder(parseInt(props.name));

  return (
    <>
      <ApplySolutionButton
        incidentId={incident.id}
        position={[0.55, -0.295, 0.18]}
      />
      <SolutionPicture
        incidentId={incident.id}
        position={[0.1, 0.025, 0.16]}
        scale={0.75}
      />
      <SolutionText incidentId={incident.id} position={[0.28, 0.35, 0.16]} />
      <QuestionText position={[-0.65, 0.35, 0.16]} />
      <Pagination incidentId={incident.id} position={[-0.17, -0.26, 0.18]} />
      <Question position={[-0.5, 0.25, 0.1]} />
      <SolutionsPile position={[0.3, 0, 0.1]} />
    </>
  );
};

export type IncidentProps = {
  name: string;
};

export const Pagination: React.FC<MeshProps & { incidentId: string }> = (
  props
) => {
  const url = useStore((s) => s.assetUrl);
  const gameState = useStore((s) => s.gameState);

  const incident = gameState.incident(props.incidentId);

  return (
    <mesh {...props}>
      <Text
        maxWidth={0.35}
        fontSize={0.05}
        lineHeight={1}
        font={url("/assets/fonts/Quantico-Bold.woff")}
        anchorY={"top"}
        anchorX={"left"}
      >
        <meshToonMaterial color={"white"} side={DoubleSide} />
        {`${incident.displayedSolutionIndex + 1}/${
          incident.applicableSolutionsCount
        }`}
      </Text>
    </mesh>
  );
};

export const QuestionText: React.FC<{
  position: [number, number, number];
}> = (props) => {
  const url = useStore((s) => s.assetUrl);
  const loader = useStore((s) => s.narrativeLoader);
  const text = loader.read("question_scelta");

  return (
    <Text
      maxWidth={0.35}
      fontSize={0.05}
      lineHeight={1}
      font={url("/assets/fonts/Quantico-Italic.woff")}
      anchorY={"top"}
      anchorX={"left"}
      position={props.position}
    >
      <meshToonMaterial color={"white"} side={DoubleSide} />
      {text}
    </Text>
  );
};

export const SolutionText: React.FC<{
  position: [number, number, number];
  incidentId: string;
}> = (props) => {
  const gameState = useStore((s) => s.gameState);
  const url = useStore((s) => s.assetUrl);
  const loader = useStore((s) => s.narrativeLoader);
  const incident = gameState.incident(props.incidentId);

  const text = loader.read(
    `decisions_${incident.id}_${incident.displayedSolution}`
  );

  return (
    <Text
      color="black"
      maxWidth={0.38}
      fontSize={0.0265}
      lineHeight={1.1}
      letterSpacing={0.05}
      font={url("/assets/fonts/Caveat-VariableFont_wght.woff")}
      anchorX={"left"}
      anchorY={"top"}
      position={props.position}
    >
      {text.toUpperCase()}
    </Text>
  );
};

export const Question: React.FC<MeshProps> = (props) => {
  const url = useStore((s) => s.assetUrl);
  const texture = useTexture(url("/assets/textures/ui/c_question.svg"));

  return (
    <mesh castShadow receiveShadow {...props}>
      <boxGeometry args={[1 * 0.6, 0.67 * 0.6, 0]} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};

export const SolutionsPile: React.FC<MeshProps> = (props) => {
  const url = useStore((s) => s.assetUrl);
  const texture = useTexture(url("/assets/textures/ui/c_answers_pile.svg"));

  return (
    <mesh castShadow receiveShadow {...props}>
      <boxGeometry args={SQUARE.clone().multiplyScalar(1).toArray()} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};

const SolutionPicture: React.FC<MeshProps & { incidentId: string }> = (
  props
) => {
  const gameState = useStore((s) => s.gameState);
  const url = useStore((s) => s.assetUrl);
  const incident = gameState.incident(props.incidentId);

  if (incident.isNull) return null;

  return (
    <Suspense fallback={null}>
      <VerticalPicture
        {...props}
        textureUrl={url(
          `/assets/textures/decisions/decision_${incident.id}_${incident.displayedSolution}.avif`
        )}
      />
    </Suspense>
  );
};

const VerticalPicture: React.FC<MeshProps & { textureUrl: string }> = (
  props
) => {
  const texture = useTexture(props.textureUrl);

  return (
    <mesh castShadow receiveShadow {...props}>
      <boxGeometry args={[0.4, 1, 0]} />
      <meshPhongMaterial map={texture} transparent={true} />
    </mesh>
  );
};
