import React from "react";
import { StartButton } from "./start-button";
import { Text } from "@react-three/drei";
import { SQUARE } from "../utils";
import { useStore } from "../../../store";

export const Cover: React.FC = () => {
  const narrativeLoader = useStore((s) => s.narrativeLoader);
  const text = narrativeLoader.read("start");

  const url = useStore((s) => s.assetUrl);

  return (
    <group>
      <Text
        color="black"
        maxWidth={SQUARE.x * 0.83}
        fontSize={0.028}
        font={url("/assets/fonts/Quantico-Bold.woff")}
        anchorX={"left"}
        anchorY={"top"}
        position={[-0.4, 0.35, 0.01]}
      >
        {text.toUpperCase()}
      </Text>
      <TransparentSquare />
      <StartButton position={[0.25, -0.36, 0.1]} />
    </group>
  );
};

const TransparentSquare: React.FC = () => (
  <mesh>
    <boxGeometry args={SQUARE.toArray()} />
    <meshStandardMaterial transparent />
  </mesh>
);
