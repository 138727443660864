import { StoreSlice } from '../utils/store-slice';
import { IScreenPlayStore } from '../interfaces/screen-play-store';
import { IPlayerCameraStore } from '../interfaces/player-camera-store';
import { ShootBuilder } from '../../scene-control/shoot-builder';
import Immutable from 'immutable';
import { Shoot } from '../../scene-control/shoot';
import { IVideoMixerStore } from '../interfaces/video-mixer-store';
import { ISoundStore } from '../interfaces/sound-store';

const s = ShootBuilder.init().setNamer((pos: string) => `comic-${pos}`);

const VIDEO_SHOOTS = Immutable.Map<Shoot, string>()
  .set(s.addComics(['0:1']).inside().make(), '1')
  .set(s.addComics(['1:3']).inside().make(), '2')
  .set(s.addComics(['2:2']).inside().make(), '3')
  .set(s.addComics(['3:1']).inside().make(), '4')
  .set(s.addComics(['4:0']).inside().make(), '5');

export const createScreenPlayStore: StoreSlice<
  IScreenPlayStore,
  IPlayerCameraStore & IVideoMixerStore & ISoundStore
> = (set, get) => ({
  nextShootIndex: 0,
  prevShootIndex: 0,
  currentShootIndex: 0,
  isLastShoot: true,
  shootList: Immutable.List<Shoot>(),
  shootingDirection: 'forward',

  shoot: (index: number) => {
    console.log(`»» now shooting «${index}»`);

    const shoot = get().shootList.get(index);
    const channel = VIDEO_SHOOTS.get(shoot);
    channel && get().playVideoChannel(channel);

    get().focusCameraOn(shoot);
    get().playSound(`camera-moves-${(Math.random() > 0.5 && 1) || 2}`);

    set(s => ({
      isLastShoot: index >= get().shootList.count() - 1,
      nextShootIndex: Math.min(index + 1, get().shootList.count() - 1),
      prevShootIndex: Math.max(index - 1, 0),
      currentShootIndex: index,
      shootingDirection: index >= s.nextShootIndex ? 'forward' : 'backward',
    }));
  },

  replaceShootList: list => {
    set(s => ({ shootList: s.shootList.clear().concat(list) }));
  },
});

export const serializeScreenPlayStore = (s: any) => ({
  nextShootIndex: s.nextShootIndex,
  prevShootIndex: s.prevShootIndex,
  shootList: s.shootList.map(Shoot.encode).toArray(),
});

export const deserializeScreenPlayStore = (raw: any) => ({
  nextShootIndex: raw.nextShootIndex,
  prevShootIndex: raw.prevShootIndex,
  shootList: Immutable.List(raw.shootList.map(Shoot.decode)),
});
