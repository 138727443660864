import React from 'react';

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const TimerContainer = ({ children }: IProps) => (
  <div
    style={{
      border: '2px solid white',
      pointerEvents: 'none',
      width: '100%',
      height: '100%',
      backgroundColor: '#1f2c39',
      padding: '2px 12px',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    }}
  >
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {children}
    </div>
  </div>
);

export default TimerContainer;
