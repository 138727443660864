import React from 'react';
import { useStore } from '../../store';
import { Table } from './table';

export const TerminalChannels = () => {
  const terminals = useStore(s => s.terminals);

  const cols = ['name', 'icon', 'message', 'claim'];
  const rows = terminals
    .map((terminal, i) => ({
      name: i,
      icon: terminal.isStarted && !terminal.isEnded ? '▶ ' : '❙❙',
      message: terminal.message,
      claim: terminal.claim,
    }))
    .toList()
    .toArray();

  return <Table cols={cols} rows={rows} />;
};
