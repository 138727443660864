import { useRef, useEffect } from "react";
import Header from "./components/Header";
import useDiagnostics from "./hooks/useDiagnostics.hook";
import { render } from "./fireballs-game-board";
import buildAssetPath from "./utils/buildAssetPath";
import useGameQueryParams from "./hooks/useGameQueryParams.hook";

export const App = () => {
  const ref = useRef<HTMLDivElement>();
  const { gamePlayInfoId, demoId, observer } = useGameQueryParams();
  useDiagnostics();

  useEffect(() => {
    if (gamePlayInfoId || demoId)
      render(ref.current, {
        gamePlayInfoId: gamePlayInfoId || demoId,
        observer,
      });

    console.log(
      `[branch|commit]: [${process.env.CF_PAGES_BRANCH}|${process.env.GIT_COMMIT_HASH}]`
    );
  });

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Header brandImg={buildAssetPath("/images/logo_full.png")} />
      <div
        style={{
          background: "#112837",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "White",
          flexGrow: 1,
        }}
      >
        {!gamePlayInfoId && !demoId ? (
          <p
            style={{
              fontSize: "2rem",
              textAlign: "center",
              fontFamily: "sans-serif",
            }}
          >
            We are sorry but the game needs at least one of: <br />
            <b style={{ fontFamily: "monospace" }}>ledsp</b> or{" "}
            <b style={{ fontFamily: "monospace" }}>demoId</b>
          </p>
        ) : (
          <div
            ref={ref}
            style={{
              minWidth: "70%",
              maxWidth: "90%",
              aspectRatio: "16 / 9",
              position: "relative",
              maxHeight: "90%",
            }}
          />
        )}
      </div>
    </div>
  );
};
