import { useStore } from '../../../store';
import { Text } from '@react-three/drei';
import React from 'react';
import { FULL } from '../utils';

export const ClaimTerminalText: React.FC<{ children: string }> = props => {
  const url = useStore(s => s.assetUrl);

  return (
    <Text
      color="black"
      maxWidth={FULL.x * 0.3}
      fontSize={0.04}
      font={url('/assets/fonts/Quantico-Italic.woff')}
      anchorX={'right'}
      anchorY={'top'}
      textAlign={'right'}
      position={[FULL.x * 0.4, 0.25, 0.01]}
    >
      {props.children}
    </Text>
  );
};
