import Immutable from "immutable";
import getLedspClient from "../../../utils/get-ledsp-client";
import { GameState } from "../../model/game-state";
import { PlayerInfo } from "../../model/game-state/interfaces";
import { IAssetsLoaderStore } from "../interfaces/assets-loader-store";
import { IBasicGameStore } from "../interfaces/basic-game-store";
import { IScriptStore } from "../interfaces/script-store";
import { ITimerStore } from "../interfaces/timer-store";
import { IVideoMixerStore } from "../interfaces/video-mixer-store";
import { StoreSlice } from "../utils/store-slice";

export const createBaseGameStore: StoreSlice<
  IBasicGameStore<GameProps>,
  IAssetsLoaderStore &
    ITimerStore &
    IVideoMixerStore &
    IScriptStore &
    IAssetsLoaderStore
> = (set, get) => ({
  isInitialized: false,
  isGameStarted: false,
  isGameEnded: false,
  gameState: GameState.Null(),
  initProps: null,

  init: (props) => {
    console.log(`»» init the store with props`, props);

    get().setAssetUrl(props.assetsBaseUrl);

    if (props.settings.configuration.playOptions.timeMode === "on")
      get().initTimer(
        Number(props.settings.configuration.playOptions.timerDurationInSeconds)
      );

    Immutable.Range(1, 6).forEach((v) => {
      get().loadVideoChannel(v.toString(), {
        videoPath: get().assetUrl("/assets/videos/_/intromission/video.webm"),
        openingThumbPath: get().assetUrl(
          "/assets/videos/_/intromission/in.avif"
        ),
        closingThumbPath: get().assetUrl(
          "/assets/videos/_/intromission/out.avif"
        ),
      });
    });

    set({
      isInitialized: true,
      gameState: GameState.Init(props.settings.configuration.players),
      initProps: props,
    });
  },

  startGame: () => {
    if (get().isGameEnded || get().isGameStarted) return;

    set({ isGameStarted: true });

    get().playScene("a-threat-to-m1");

    getLedspClient().sendGameProgressEvent({
      eventType: "game-started",
      gameId: get().initProps.gameId,
      playerId: get().initProps.playerId,
      teamId: get().initProps.team.id,
    });
  },

  gameOver: () => {
    if (get().isGameEnded) return;

    fetch(get().initProps.settings.configuration.gameResultsRegistryEndpoint, {
      method: "post",
      body: JSON.stringify(GameStateToResultPayload(get())),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(() => {
        set({ isGameEnded: true });
        get().recordLap(Date.now());
        get().playScene("game-over");
      })
      .catch((error) => console.error(error));
  },

  gameClear: () => {
    if (get().isGameEnded) return;

    fetch(get().initProps.settings.configuration.gameResultsRegistryEndpoint, {
      method: "post",
      body: JSON.stringify(GameStateToResultPayload(get())),
      headers: {
        "content-type": "application/json",
      },
    })
      .then(() => {
        set({ isGameEnded: true });
        get().recordLap(Date.now());
        get().stopTimer();
      })
      .catch((error) => console.log(error));
  },

  recordPlayerChoice: (playerId: string, incident: string, choice: string) => {
    set((s) => ({
      gameState: s.gameState.recordPlayerSolution(playerId, incident, choice),
    }));

    if (get().gameState.canTeamApplySolutionTo(incident))
      getLedspClient().sendGameProgressEvent({
        eventType: "game-stage-entered",
        gameId: get().initProps.gameId,
        playerId: get().initProps.playerId,
        teamId: get().initProps.team.id,
        step: `Incident ${get().gameState.currentIncident().id[0]}`,
        stage: "Team decision",
      });
  },

  recordTeamChoice: (incident: string, choice: string) => {
    if (get().gameState.incidentHasBeenSolved(incident)) return;

    get().recordLap(Date.now());

    set((s) => ({
      gameState: s.gameState.recordTeamSolution(incident, choice),
      isSolutionSelectorVisible: false,
    }));
  },

  displaySolution: (incident, solutionIndex) => {
    set((s) => ({
      gameState: s.gameState.displaySolution(incident, solutionIndex),
    }));
  },

  prepareIncidentAfter: (incident: string) => {
    if (!get().gameState.incidentHasBeenSolved(incident)) return;

    set((s) => ({
      gameState: s.gameState.prepareIncidentAfter(incident),
      isSolutionSelectorVisible: true,
    }));
  },

  isUIOn: false,
  isSolutionSelectorVisible: false,
  isNavigationVisible: true,

  turnUIOn: () => {
    set({ isUIOn: true });
  },
  turnUIOff: () => {
    set({ isUIOn: false });
  },

  turnSolutionSelectorOff: () => {
    set({ isSolutionSelectorVisible: false });
  },

  turnSolutionSelectorOn: () => {
    set({ isSolutionSelectorVisible: true });
  },

  turnNavigationOff: () => {
    set({ isNavigationVisible: false });
  },

  turnNavigationOn: () => {
    set({ isNavigationVisible: true });
  },

  continue: () => ({}),
  continueLabel: "Continue",

  setContinue: (fn: () => void, label: string = "Continue") => {
    set({ continue: fn, continueLabel: label });
  },

  hasHydrated: true,
});

export const serializeBaseGameStore = (s) => ({
  isInitialized: s.isInitialized,
  isGameEnded: s.isGameEnded,
  gameState: GameState.encode(s.gameState as GameState),
});

export const deserializeBaseGameStore = (raw) => ({
  isInitialized: raw.isInitialized,
  isGameEnded: raw.isGameEnded,
  gameState: GameState.decode(raw.gameState),
  continue: () => ({}),
  hasHydrated: true,
});

export type GameProps = {
  readonly assetsBaseUrl: string;
  readonly gameId: string;
  readonly playerId: string;
  readonly team: { id: string; name: string };
  readonly settings: {
    readonly configuration: {
      readonly playOptions: Record<string, string | number>;
      readonly players: PlayerInfo[];
      readonly returnPath: string;
      readonly gameResultsRegistryEndpoint: string;
    };
  };
};

function GameStateToResultPayload(
  store: IBasicGameStore<GameProps> & ITimerStore
): FireballsGameResult {
  return {
    meta: {
      startDate: new Date(),
      endDate: new Date(),
      playTimeInMillis: 0,
    },

    payload: {
      observations: [
        {
          type: "team",
          object: store.initProps.team.id,
          solutionA: store.gameState.appliedSolutionAt(0),
          solutionB: store.gameState.appliedSolutionAt(1),
          mode: store.initProps.settings.configuration.playOptions.timeMode.toString(),
          isTimeOver: store.isTimerExpired,
        },
        ...store.initProps.settings.configuration.players.map((p) => ({
          type: "player",
          object: p.id,
          solutionA: store.gameState.readPlayerSolutionAt(p.id, 0),
          solutionB: store.gameState.readPlayerSolutionAt(p.id, 1),
          mode: store.initProps.settings.configuration.playOptions.timeMode.toString(),
          isTimeOver: store.isTimerExpired,
        })),
      ],
    },
  };
}

type FireballsGameResult = {
  readonly meta: {
    startDate: Date;
    endDate: Date;
    playTimeInMillis: number;
  };
  readonly payload: FireballsGameResultPayload;
};

type FireballsGameResultPayload = {
  readonly observations: FireballObservation[];
};

type FireballObservation = {
  type: string;
  object: string;
  solutionA: string;
  solutionB: string;
  mode: string;
  isTimeOver: boolean;
};
