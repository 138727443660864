import { useHelper } from '@react-three/drei';
import { GroupProps, useFrame } from "@react-three/fiber";
import React, { ReactNode, useRef } from "react";
import { BoxHelper, Group, Object3D, SpotLight, SpotLightHelper } from "three";
import { useStore } from "../../store";
import { Comic } from "../comic";
import { ComicStrip } from "./comic-strip";
import { ComicStripRow } from "./comic-strip-row";

export const ComicPage: React.FC<GroupProps> = (props) => {
  const comics = useStore((s) => s.comicsLayout);
  const shootList = useStore((s) => s.shootList);

  const isLightOn = useStore((s) => s.isLightOn);
  const areVisualGuidesVisible = useStore((s) => s.areVisualGuidesVisible);

  return (
    <group {...props}>
      <ComicStrip>
        {comics.map((row, i) => (
          <ComicStripRow key={i}>
            {row.map((comic, j) => {
              const [type, name] = comic.split("-");
              const comicName = `comic-${i}:${j}`;

              const group = useRef<Group & ReactNode>(null);
              const comicObject = useRef<Object3D & ReactNode>(null);
              const light = useRef<SpotLight & ReactNode>(null);

              useFrame(() => {
                light.current.target = comicObject.current;
              });

              useHelper(areVisualGuidesVisible && light, SpotLightHelper);
              useHelper(areVisualGuidesVisible && group, BoxHelper, "cyan");

              return (
                <group ref={group} key={j} name={`comic-${i}:${j}`}>
                  <spotLight
                    ref={light}
                    intensity={
                      shootList.some((s) => s.contains(comicName)) ? 1 : 0
                    }
                    color="#ffccaa"
                    // color="white"
                    penumbra={0.6}
                    distance={10}
                    angle={Math.PI / 12}
                    position={[0, 0, -5]}
                  />
                  <object3D ref={comicObject}>
                    <Comic type={type} name={name} />
                  </object3D>
                </group>
              );
            })}
          </ComicStripRow>
        ))}
      </ComicStrip>
    </group>
  );
};
