"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GAME_PROGRESS_EVENT_TYPES = exports.GAME_ENDED = exports.GAME_STAGE_ENTERED = exports.GAME_STARTED = exports.GAME_LAUNCHED = void 0;
exports.GAME_LAUNCHED = "game-launched";
exports.GAME_STARTED = "game-started";
exports.GAME_STAGE_ENTERED = "game-stage-entered";
exports.GAME_ENDED = "game-ended";
exports.GAME_PROGRESS_EVENT_TYPES = [
    exports.GAME_LAUNCHED,
    exports.GAME_STARTED,
    exports.GAME_STAGE_ENTERED,
    exports.GAME_ENDED,
];
