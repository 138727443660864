/* eslint-disable default-case */
/* eslint-disable indent */
import React, { useMemo } from 'react';
import useEvent from '@react-hook/event';
import { Stats } from '@react-three/drei';
import { useStore } from '../../store';
import { GameStateReporter } from './game-state-reporter';
import { styles } from './styles';
import { TerminalChannels } from './terminal-channels';
import { VideoChannels } from './video-channels';

export const DevTools = () => {
  const store = useStore();
  const s = useMemo(() => styles(), [1]);

  useEvent(window, 'keydown', ev => {
    switch (ev.key) {
      case 'h':
        store.toggleVisualGuides();
        break;
      case 'd':
        store.toggleDevTools();
        break;

      case 'u': {
        if (store.isUIOn) store.turnUIOff();
        else store.turnUIOn();
        break;
      }

      case 'ArrowDown': {
        store.areDevToolsVisible && store.playScene(store.nextSceneIndex);
        break;
      }

      case 'ArrowUp': {
        store.areDevToolsVisible && store.playScene('cover');
        break;
      }
    }
  });

  if (!store.areDevToolsVisible) return null;

  return (
    <div style={s.main}>
      <GameStateReporter />
      <br />
      <strong>Info</strong>
      <br />
      is timer started: {store.isTimerStarted.toString()}
      <br />
      timer laps: {store.laps.join('  ')}
      <br />
      isUIOn: {store.isUIOn.toString()}
      <br />
      areVisualGuidesVisible: {store.areVisualGuidesVisible.toString()}
      <br />
      isSuperFocusOn: {store.isSuperFocusOn.toString()}
      <br />
      --
      <br />
      baseAssetsUrl: {store.assetsBaseUrl}
      <br />
      currentScene: {store.currentScene}
      <br />
      nextScene: {store.nextSceneIndex}
      <br />
      shootList:{' '}
      {store.shootList
        .map(s => s.toString())
        .toArray()
        .join(' | ')}
      <br />
      nextShootIndex: {store.nextShootIndex.toString()}
      <br />
      prevShootIndex: {store.prevShootIndex.toString()}
      <br />
      --
      <br />
      <strong>Video Channels</strong>
      <br />
      <VideoChannels />
      --
      <br />
      <strong>Terminal Channels</strong>
      <br />
      <TerminalChannels />
      <Stats />
    </div>
  );
};
